<template>
  <v-app>
    <router-view></router-view> <!-- Vue Router will control the page content here -->
  </v-app>
</template>

<script>
export default {
  name: 'App',
  // No need to import or declare components here since Vue Router handles it
}
</script>

<style>
/* Your global styles */
</style>
