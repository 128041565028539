<template>
  <v-app :class="{ 'blur-out': showMenu }" app>
    <AppBar :is-mobile="isMobile" :is-dark-mode="isDarkMode" :menu-entries="menuEntries" :show-menu="showMenu" @toggle-menu="handleToggleMenu" @navigate="navigate" @close-menu="showMenu = false" />

    <v-main>

      <div class="about-container" id="about">
     <div class="about-sec">
    <h1 class="about-heading">About</h1>
    <h2 class="about-subtitle">Philosophy</h2>
    <p class="about-text">At the core of <b>ITHAS</b>, lies a philosophy rooted in the belief that technology should serve as a beacon, 
      guiding humanity towards simplicity, understanding, and enlightenment, rather than misleading us in further complexity. 
      The essence of technology is transformational – a force capable of rendering the inconceivable into tangible reality. <i>ITHAS</i> is set on 
      refining raw potential into solutions that breathe life into the abstract.
    What is more, we are not just engineers or programmers; we are the architects of dreams, builders of bridges between worlds. 
    Our work is not defined by the sectors we serve but by the vision we share - a world made simpler, brighter, and endlessly fascinating
     by the thoughtful application of technology.</p>
    <!-- Additional paragraphs as needed -->
    <h2 class="about-subtitle">Technological palette</h2>
    <p class="about-text">The technological palette, featuring <i>UNIX, C / C++, SQL databases, and the foundational pillars of the web (as overseen by W3C and ECMA)</i>, 
      is underpinned by a rigorous selection process that values integrity, reliability, and the timeless principles of <i>computer science</i>. 
      They are chosen for their ability to articulate complex solutions with elegance and precision.

<i>ITHAS</i> remains ardently open to the whispers of innovation, to the potential of new ideas and tools that promise to expand the 
horizons of what is possible. Yet, this openness is not without discernment. The ingress of new technologies is selective, deliberate, 
thoughtful process, governed by a discerning eye and rigorous evaluation of their value, their philosophical alignment.
</p>
<h2 class="about-subtitle">Cooperation</h2>
    <p class="about-text">You are invited, fellow dreamers and innovators, in this grand experiment of the digital age. 
      Let us pursue the future where we stand united in a quest to distill complexity into clarity.

In this digital mythology, every line of code and every circuit pulses with the lifeblood of human aspiration. 

This is not merely a mission statement at <i>ITHAS</i> but a solemn vow: to honor the legacy of those who have walked before us and to lay the 
foundations for those who will follow. We stand at the confluence of past and future, tradition and innovation, 
with power to manifest the vision of <i>technology not as a burden, but as a liberating force, a door to a world where every human endeavor is 
  infused with meaning and purpose.</i></p>
    <!-- Additional paragraphs as needed -->
  </div>
  </div>


<div>
  <h2 class="services-heading">What You Get with ITHAS?</h2>
  <div class="categories-section">
  <div class="category">
        <div class="feature-icon terminal-icon">
          <span class="no-select">> _</span>
        </div>
    <h3 class="category-title">Server management</h3>

    <p class="category-description">Comprehensive server management and solutions of unparalleled reliability and scalability. Built on bulletproof security and unbreakable ZFS filesystem. End-to-end oversight, from operating systems to application and web servers, databases, virtualization and performance optimization. All while allowing you to focus on your core business free of technical concerns.</p>
  </div>
  <div class="category">
        <div class="feature-icon sql-icon">
          <span class="no-select">SQL&gt;</span>
        </div>
    <h3 class="category-title">Database solutions</h3>
    <p class="category-description">Specialization in industry-leading platforms such as Oracle, PostgreSQL, MariaDB, and more, with proven track-record in financial, medical, educational sector. You get database design, administration, migration and development. With Oracle, you get whole ecosystem, featuring Forms & Reports, 
    and Oracle APEX oriented development</p>
  </div>
  <div class="category">
  <div class="feature-icon team-icon">
        <span class="no-select">&lt;/&gt;</span>
        
      </div>
    <h3 class="category-title">Web development</h3>
    <p class="category-description">Immersive experience using Vanilla JS, Vue.js, and React. Combining the best of front-end and back-end technology to create web solutions that captivate your users. </p>
  </div>
  <div class="category">
  <div class="feature-icon code-icon">
        <span class="no-select">{}</span>
        
      </div>
    <h3 class="category-title">Software Development</h3>
    <p class="category-description">Portable, native, and architecture specific applications. 
      <br/>-Kernel modules and device-drivers on FreeBSD.
      <br/>-Custom software with attention to performance and processing speed</p>
    
    
  </div>
  <!-- Add more categories as needed -->
</div>

</div>


<div style="display: flex; justify-content: center; align-items: center;">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="5" style="text-align: center;">
              <h2 style="margin-bottom: 20px;">Embedded Solutions and Development</h2>
              <img src="@/assets/cpu.png" alt="CPU Image" style="max-width: 100%; display: block; margin: 0 auto 20px;">
              <p class="background-overlay"> Services that meet the the complex demands of modern hardware engineering.
                Assembling solutions with goal to combine state-of-the-art embedded technology and real-world applications
                that can contribute to health, fitness, and beyond.
                <br><br>
                <i>Node.js</i> or <i>Vapor(with Swift)</i> for real-time data exchange. <br>
                <i>FreeRTOS</i> implementation.<br>
                Firmware with <i>C and Assembly</i> .<br>
                <br><br>
                Partner with <i>ITHAS</i> for your embedded systems projects, and guarantee your hardware is infused with
                reliable, finely-tuned, and optimized software.
                <br><br>
              </p>

            </v-col>
          </v-row>
        </v-container>
      </div>

    </v-main>
  </v-app>






  <!-- Modern Footer -->
  <v-footer class="modern-footer">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="6" class="footer-section">
          <router-link to="/about" class="footer-link" :class="{'footer-title-dark': isDarkMode}">
  <h3 class="footer-title">About</h3>
</router-link>

          <p class="footer-content">UNIX Engineering<br>Storage Management<br>Oracle Database and APEX<br>Software
            Development<br>Embedded Systems</p>
        </v-col>
        <v-col cols="12" md="6" class="footer-section">
          <h3 class="footer-title">Contact</h3>
          <p class="footer-content">
            <b>Email:</b> <a href="mailto:contact@ithas-site.com"
              style="color: inherit; text-decoration: none;">contact@ithas-site.com</a><br />
          </p>
        </v-col>
      </v-row>

      <v-row justify="center" class="social-icons">
        <v-col cols="12">
          <a href="https://www.linkedin.com/company/ithas/" target="_blank" rel="noopener noreferrer"
            style="color: YOUR_DESIRED_COLOR; text-decoration: none;">
            <v-icon size="24" class="social-icon">mdi-linkedin</v-icon>
          </a> <span>&copy; ITHAS 2023</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import 'vuetify/dist/vuetify.css';
import AppBar from '@/AppBar.vue';
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure FontAwesome is imported

//import FullWidthSection from '@/components/FullWidthSection.vue'; 
//import prismicClient from '../prismic.js';
//import Prismic from 'prismic-javascript';
//import { Predicates } from '@prismicio/client';
//import { usePrismic } from '@prismicio/vue';



export default {
  components: {
    //FullWidthSection,  Register the FullWidthSection component
    AppBar,
  },
  data() {
    return {
      particleStyles: [
      { width: '30px', height: '30px', filter: 'blur(1px)', transform: 'translate(5vw, 5vh)', backgroundColor: 'hsl(0, 0%, 80%)' },
  { width: '20px', height: '20px', filter: 'blur(0.3px)', transform: 'translate(20vw, 10vh)', backgroundColor: 'hsl(0, 0%, 30%)' },
  { width: '10px', height: '10px', filter: 'blur(1px)', transform: 'translate(35vw, 15vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '35px', height: '35px', filter: 'blur(0.5px)', transform: 'translate(50vw, 20vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '10px', height: '10px', transform: 'translate(65vw, 25vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '60px', height: '60px', filter: 'blur(1.0px)', transform: 'translate(80vw, 30vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '4px', height: '4px', filter: 'blur(0.3px)', transform: 'translate(10vw, 45vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  // Additional particles for even coverage
  { width: '25px', height: '25px', filter: 'blur(0.4px)', transform: 'translate(25vw, 55vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '15px', height: '15px', filter: 'blur(0.5px)', transform: 'translate(40vw, 65vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '20px', height: '20px', filter: 'blur(0.6px)', transform: 'translate(55vw, 75vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '50px', height: '50px', filter: 'blur(0.7px)', transform: 'translate(70vw, 85vh)', backgroundColor: 'hsl(0, 0%, 90%)' },

        // Add more particles as needed
      ],
      /*email Netlify Form*/
      valid: true,
      name: '',
      email: '',
      message: '',
      nameRules: [
        v => !!v || 'Name is required', // Checks for non-empty input
        v => (v && v.length <= 50) || 'Name must be less than 50 characters', // Limits the length of the name
      ],
      emailRules: [
        v => !!v || 'E-mail is required', // Checks for non-empty input
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid', // Uses regex to check for a valid email format
      ],
      messageRules: [
        v => !!v || 'Message is required', // Checks for non-empty input
        v => (v && v.length <= 500) || 'Message must be less than 500 characters', // Limits the length of the message
      ],
      /* end of email */

      carouselValue: 0,

      mobileScreen: window.innerWidth <= 768,
      aboutTitle: null, //prismic 
      unixFeatureContent: '', // Initialize as an empty string, prismic  
      response: null,
      scrollPosition: 0,
      isMobile: false, // Add a flag to check if the screen size is mobile or not
      isDarkMode: false,
      isShrinking: false,
      showFullWidthSection: true,
      show: false,
      showCover: true,
      isFlyerOpen: false, //for flyer
      /*ratingValue: 0,
        customIcons: {
          // Use Font Awesome icons for the rating stars
          iconFull: 'fas fa-star', // fas fa-star is for a filled star
          iconHalf: 'fas fa-star-half-alt', // fas fa-star-half-alt is for a half-filled star
          iconEmpty: 'far fa-star', // far fa-star is for an empty star
        },*/
      cardTitle: "Oracle Database and Application Development",
      cardContent: "Implementation, configuration and administration of Oracle Database. Data-driven application development with Oracle APEX.",
      timelineItems: [
        {
          title: 'Storage Management', // Replace with your custom title
          content: 'Data integrity in HA environments with <b>ZFS</b>. <br>Implemented and administrated ZFS storage pools across data centers.<br>Optimization and performance tuning for <i>Elasticsearch</i><br>',
          color: 'white',
        },
        {
          title: 'System Engineering', // Replace with your custom title
          content: 'Built and supported multiple systems based on <i>FreeBSD</i>, <i>Illumos</i> and <i>Linux</i>.<br>Provided consultation and support for legacy <i>Solaris 10</i> environments<br>Provisioning and advanced containerization with <i>SmartOS</i><br>Native configuration management',
          color: 'white',
        },
        {
          title: 'Oracle Database',
          content: '10 years of proven track record of delivering excellence in public, health, financial and educational sectors.<br>Oracle Database implementation and administration, backup and security<br>Oracle APEX development from version 4.1 to 23.1<br><i>Over 50 projects</i><br><b>Oracle Forms</b> to <b>APEX</b> migration',
          color: 'white',
        },
        {
          title: 'Software and Web Development', // Replace with your custom title
          content: 'Designed and implemented <i>Vue.js</i> solutions, complemented by robust <i>Node.js</i> backends.<br><br>Collaborated to build high-quality platforms and a range of proprietary products.<br><br>Built highly custom JavaScript solutions powered by <i>Vue.js framework</i> in tandem with <i>Oracle APEX</i><br><br>C/C++ development',
          color: 'white',
        },
        // Add more timeline items as needed
      ],

      focusedIndex: 0,

      showMenu: false,
      menuEntries: [
        { id: 'home', label: 'Home' },
        { id: 'portfolio', label: 'Portfolio' },
        { id: 'about', label: 'About' },
        { id: 'contact', label: 'Contact' },
      ],
    };
  },

  mounted() {


   // Add a click event listener to the whole document
   document.addEventListener('click', this.handleClickOutside, true);


  

    //console.log(this.$prismic);


    this.checkIsMobile(); // Call the method on mount to set the initial isMobile value


    // Add a resize event listener to update the isMobile flag
    window.addEventListener('resize', this.checkIsMobile);


 

  },

  beforeUnmount() {
    // Use beforeUnmount instead of beforeDestroy
    window.removeEventListener('resize', this.checkIsMobile); // Remove the resize event listener on component unmount


    if (this.observer) {
      this.observer.disconnect(); // Stop observing when component is destroyed
    }

    document.removeEventListener('click', this.handleClickOutside, true);
  },




  methods: {
   /* handleToggleMenu(show) {
    this.showMenu = show;
  },*/
  handleToggleMenu(showMenu) {
  this.showMenu = showMenu;
  //console.log('showMenu updated to:', showMenu);
},


    
  handleClickOutside(event) {
    // Check if the click is outside the AppBar element
    if (!this.$el.contains(event.target) && this.showMobileMenu) {
      this.toggleMobileMenu(); // Close the mobile menu
      this.$emit('close-menu'); // Emit an event to notify the parent
    }
  },

    toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
  },


    /*email methods*/
    submit() {
      if (this.$refs.form.validate()) {
        // Here you would typically send the form data to your server
        // For Netlify, the form element handles the submission

      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    }
    ,
    validate() {
      this.valid = this.$refs.form.validate();
    },


   
  



    // next asyncs are fetching the content from Prismic docs and types. API ID is used so for aboutTitle type, API ID from prismic is "title"
    // also they need to be called in created(), to call the methods on load. and defined / initialized in data() return for bind variables, like:
    //aboutTitle: null,
    //unixFeatureContent: ''
    //then used in template to show their Prismic content, ie: <h2 class="about-title">{{ aboutTitle }}</h2>

    async getContent() {
      try {
        const docId = 'ZS-88REAACEASxWD'; //Doc ID is found from URL in Prismic when editing document
        const response = await this.$prismic.client.getByID(docId);
        //console.log("Prismic Response:", response);

        if (response && response.data && response.data.title && response.data.title.length > 0) {
          this.aboutTitle = response.data.title[0].text;
        }
      } catch (error) {
       // console.error('Error fetching data from Prismic:', error);
      }
    },

    // method to fetch unixFeatureContent from Prismic
    //API ID is used so for aboutTitle type, API ID from prismic is "unixfeaturecontent" lowercase
    async fetchUnixFeatureContent() {
      try {
        const docId = 'ZTvSvhEAACUAYirp';
        const response = await this.$prismic.client.getByID(docId);

        if (response && response.data && response.data.unixfeaturecontent && response.data.unixfeaturecontent.length > 0) {
          this.unixFeatureContent = response.data.unixfeaturecontent[0].text;
        }
      } catch (error) {
        //console.error('Error fetching data from Prismic:', error);
      }
    },


    // Function to scroll to the top of the page
    scrollToTop() {
      window.scrollTo({
        top: 64,
        behavior: 'smooth',
      });
    }, checkIsMobile() {
      // Method to check if the screen size is mobile or not
      this.isMobile = window.innerWidth <= 767.98;
    },
    toggleMenu() {
      // Only toggle the showMenu state if the screen size is mobile
      //if (this.isMobile) {
        this.showMenu = !this.showMenu;
        //console.log('Menu toggled:', this.showMenu);
      //}
    },
    closeMenu() {
      this.showMenu = false;
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    navigate(event, link) {
      // Prevent the default link behavior if the event is provided
      if (event) event.preventDefault();

      if (link === 'contact') {
        window.location.href = 'mailto:contact@ithas-site.com';
      } else if (link === 'home' || link === 'portfolio') {
        this.scrollToSection(link);
      } else {
        this.scrollToSection('about');
      }

      // Close the mobile menu after clicking
      this.showMenu = false;
    },
    toggleExpand(index) {
      if (this.show === index) {
        this.show = null; // Collapse if already expanded
      } else {
        this.show = index; // Expand the clicked card
      }
    },

    openFlyer() {
      this.showCover = false;

      this.$nextTick(() => {
        this.$refs.flyerContainer.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        setTimeout(() => {
          this.isFlyerOpen = true;
        }, 100);
      });
    },
    /* closeFlyer() {
       this.isFlyerOpen = false;
       setTimeout(() => {
         this.showCover = true;
       }, 500);
     },*/
    closeFlyer() {
      this.isFlyerOpen = false;
      setTimeout(() => {
        this.showCover = true;
      }, 500);  // adjust duration based on when you want the cover to appear in relation to the content fade/fly animation.
    },

  },
  created() {
    
    this.getContent();
    this.fetchUnixFeatureContent();
  },

  // ... other code ...
};

</script>

<style scoped>
@import url('https://fonts.cdnfonts.com/css/terminus');
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');


.about-sec {
  text-align: center;
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.about-heading {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.about-subtitle {
  font-size: 1.5em;
  color: #777;
  margin-bottom: 1em;
    text-align: left;
padding-left: 15px;
}

.about-text {
  text-align: left;

  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 1em;
  padding-left: 20px; /* Ensure text doesn't touch the border */
  position: relative; /* Needed for the pseudo-element to position correctly */
}

.about-text::before {
  content: '';
  position: absolute;
  left: 0;
  top: 25%; /* Start the line at 25% from the top */
  bottom: 25%; /* End the line at 25% from the bottom, making it 50% of the element's height */
  width: 4px; /* Border width */
  background-color: grey; /* Border color */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .about-heading {
    font-size: calc(2.5em - 3px); /* Reduce by 5px */
  }

  .about-subtitle {
    font-size: calc(1.5em - 3px); /* Reduce by 5px */
  }

  .about-text {
    font-size: calc(1em - 3px); /* Reduce by 5px */
  }
}

.services-heading {
  margin-bottom: 1em;
  text-align: center;

}

.categories-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}

.category {
  background-color: #f9f9f9; /* Solid color background */
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for hover effects */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Subtle shadow for depth */

}

/*
.category:hover {
  transform: translateY(-5px); 
  box-shadow: 0 10px 20px rgba(0,0,0,0.2); 
}*/

.category-title {
  margin-top: 0;
  font-weight: bold;
  color: #333; /* More pronounced title color */
  margin-bottom: 10px; 
}

.category-description {
  margin-bottom: 0;
  font-size: 0.9em; /* Adjusted for readability */
}

@media (max-width: 768px) {
  .categories-section {
    grid-template-columns: 1fr;
  }
}


/* define font face for San Francisco Pro Display, which is font stored in assets. Another one is called SF Pro Display and referenced from CDN*/ 
@font-face {
  font-family: 'San Francisco Pro Display';
  src: url('~@/assets/fonts/SF-Pro-Display-Ultralight.otf') format('opentype');
  font-weight: 100; /* Ultralight */
  font-style: normal;
}


.dark-mode {
  background-color: #344955; /* Dark background */
  color: white; /* Light text */
}

.hero-container {
  margin-top: 50px;
  
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url('@/assets/hero-bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden; /* Ensures the pseudo-element does not extend outside the container */
  /*border-bottom: 2px solid black;*/
}

.hero-container.dark-mode-bg {
  background-color: #35374B; /* Change background to black in dark mode */
  background-image: none; /* Optionally remove the image in dark mode */
}

.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.97) 50%, rgba(255, 255, 255, 1) 100%);
  z-index: 0;
}

/* Ensures this layer is behind the content but above the existing ::before layer */
/*
.hero-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom left, transparent 70%, grey 20%); 

  z-index: 0; 
}
*/


.hero-container.dark-mode-bg::before {
  background: linear-gradient(to right, rgba(53, 55, 75, 0.97) 50%, rgba(53, 55, 75, 1) 100%);

}

.hero-container>* {
  position: relative;
  z-index: 1;
}

.paragraph-text-left {
  text-align: left;
  font-weight: bold;
  color: #35374B;
}

.gradient-text-artofcode {
  background: -webkit-linear-gradient(left, black, #26A69A);
  /* for Chrome, Safari */
  background: linear-gradient(to right, black, #26A69A);
  /* Standard syntax */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Make the text color transparent */
  font-size: 32px !important;
  /* Set the font size */
  font-weight: 900;
  /* Set the font weight */
  font-family: 'SF Pro Display', sans-serif;
}

.gradient-text-artofcode-dark {
  background: linear-gradient(to right, white, #26A69A); /* White to #26A69A for dark mode */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 32px !important;
  font-weight: 900;
  font-family: 'SF Pro Display', sans-serif;
}

.hero-row {
  text-align: center;
}

@media (max-width: 960px) {

  /* Adjust breakpoint as needed */
  .hero-container {
    margin-top: 100px;
    /* Adds space above the hero section on smaller screens */

  }

  .hero-row .v-col.text-left {
    text-align: center !important;
  }

  .hero-row img {
    display: block;
    /* Makes the image a block element */
    margin: 0 auto;
    /* Centers the image horizontally */
    width: 50%;
    /* Adjust width as necessary, or use max-width to prevent scaling */
  }
}

.display-1 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.subheading {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.v-btn {
  padding: .5rem 2.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 60%;
  /* Set the desired height relative to the parent container */
  width: auto;
  padding-left: 35px !important;
  /* Move the logo 50px to the right */

}

.dark-mode-logo {
  filter: brightness(0) invert(1);
}

/* Adjust the size of the v-toolbar-title */
.v-toolbar-title {
  font-size: 0;
  /* Set font size to 0 to remove any extra space */
}

/* Adjust the size of the logo within the v-toolbar-title */
.v-toolbar-title img.logo-image {
  height: 40px;
  /* Set the desired height of the logo */
  width: auto;
  /* Automatically adjust the width based on the height */
}

.partners-section {
  padding: 20px 0;
  /* Adjust padding as necessary */
}

.partner-image {
  object-fit: contain;
  width: 100%;
  /* or any specific width */
  height: auto;
  /* maintain aspect ratio */
  margin: 0 50px;
  filter: brightness(0) invert(1);
  /* to use CSS trick to make all different colored logos appear all white */
}

.smaller-logo {
  width: 70%;
  /* Adjust the width as needed */
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.partners-heading {
  user-select: none; 
  color: white;
  animation: 1s ease-out 0s 1 slideInFromRight;
  font-weight: 500;
  font-family: 'SF Pro Display', sans-serif;
}

.partners-section .v-col .partner-image {

  margin: 0 50px;
  /* Horizontal margin for spacing */
}

.dark-bg {
  background-color: #4f4f4f;
  /* Dark grey background */
}

.color-section {
  background-color: #ebe6db;
  /* Set the desired color */
  width: 100%;
  height: 50vh;
  /* Set the desired height, adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  text-align: center;
  flex: 1;
}

.section-title {
  font-family: 'Roboto', sans-serif;
  /* Use the desired font name */
  font-size: 28px;
  margin-bottom: 10px;
}

.section-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.menu-entries {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-entry {
  font-size: 16px;
  font-weight: bold;
  margin-left: 16px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: black;
  /* Set the font color to #14566B */
}

.menu-entry-dark {
  color: white; /* Color for dark mode */
}

.menu-entry::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background: none;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.menu-entry:hover::before {
  visibility: visible;
  transform: scaleX(1);
  background: linear-gradient(to right, gold, goldenrod);
}

/* Container for Features */
.feature-container {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  /* Add this line to make positioning easier */
}

/* no transparency and overlay*/ 
/*.first-container {
  width: 100%; 
  height: 50vh; 
  background-image: url('~@/assets/particles.png'); 
  background-repeat: repeat; 
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
}*/

/* transparency and overlay*/
.first-container {
  width: 100%;
  background-image: url('~@/assets/particles.png');
  background-repeat: repeat; /* Repeat the image to cover the whole area */
  background-size: cover; /* Cover the entire container */
  background-position: center top; /* Center the background image at the top */
  display: flex;
  justify-content: center;
  position: relative;
}

@media (min-width: 768px) { /* Adjust this breakpoint as needed */
  .first-container {
    padding-top: 5%; 
    padding-bottom: 2%; /* Adjust padding to ensure proper spacing */
  }

  .first-container::before {
    /* Gradient overlay that fades from transparent at the top to opaque towards the bottom */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
}

@media (max-width: 767px) {
  .first-container {
    padding-bottom: 0; /* Adjust padding for smaller screens */
  }

  .first-container::before {
    /* Remove the gradient effect on smaller screens, or adjust as necessary */
    background: none;
  }
}

.first-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; /* Ensure the overlay is below the content to not interfere with it */
}

/* Ensure content is placed above the overlay */
.first-container > * {
  position: relative;
  z-index: 1;
}



.content-inner {
  width: 80%; /* Less than the full width, adjust as needed */
  padding: 0px 0; /* Top and bottom padding, no horizontal padding */
  
}

.section-div {
  border: 1px solid #35374B;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #4f4f4f; /* Light grey background */
  color: white;
  border-radius: 15px;
}

.opacity-75 {
  opacity: 0.75;
}


/* Ensure the content within v-container overlays the SVG */
.v-container > .v-row {
  position: relative;
  z-index: 1;
}

::v-deep .theme--light.v-timeline:before {
    background-color: red !important;
}


.action-bar-icon {
  width: 32px;
  /* Adjust the width as needed */
  height: 32px;
  /* Adjust the height as needed */

  opacity: 0.5;
  /* Set the initial opacity to control the sides' visibility */

}




/* Terminal Icon */
.feature-icon.terminal-icon {
  /* Add styles to match the other features */
  width: 60px;
  /* Adjust the size of the icon as needed */
  height: 40px;
  /* Adjust the size of the icon as needed */
  background-color: black;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: monospace;
  font-size: 16px;
  /* Adjust the font size of the icon text */
  position: relative;
  /* Change the position to relative */
  margin: 0 auto 20px;
  /* Center the icon horizontally and add margin to push the card content down */
}

/* Prevent text selection */
.feature-icon.terminal-icon span {
  user-select: none;
}

/* SQL Icon */
.feature-icon.sql-icon {
  /* Add styles to match the other features */
  width: 60px;
  /* Adjust the size of the icon as needed */
  height: 40px;
  /* Adjust the size of the icon as needed */
  background-color: #344955;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: monospace;
  font-size: 16px;
  /* Adjust the font size of the icon text */
  position: relative;
  /* Change the position to relative */
  margin: 0 auto 20px;
  /* Center the icon horizontally and add margin to push the card content down */
}

/* Prevent text selection */
.feature-icon.sql-icon span {
  user-select: none;
}


/* Feature Title */
.feature-title {
  color: black;
  /*#14566B;  Set the title color to #14566B */
  font-size: 24px;
  /* You can adjust the font size as needed */
  margin-bottom: 10px;
}

/* Feature Content */
.feature-content {
  color: black;
  /*#2F879F;  Set the content text color to #2F879F */
  font-size: 16px;
  /* You can adjust the font size as needed */
}

/* </> Icon Styles */
.feature-icon.team-icon {
  width: 60px;
  /* Adjust the size of the icon as needed */
  height: 40px;
  /* Adjust the size of the icon as needed */
  background-color: #50727B;
  /* Set the background color to the desired color */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  /* Set the icon text color to white */
  font-family: monospace;
  font-size: 16px;
  /* Adjust the font size of the icon text */
  position: relative;
  /* Change the position to relative */
  margin: 0 auto 20px;
  /* Center the icon horizontally and add margin to push the card content down */
}

/* Prevent text selection */
.feature-icon.team-icon span {
  user-select: none;
}

.feature-icon.team-icon i.gear-icon {
  font-size: 12px;
  /* Adjust the size of the gear icon */
}

/* </> Icon Styles */
.feature-icon.code-icon {
  width: 60px;
  /* Adjust the size of the icon as needed */
  height: 40px;
  /* Adjust the size of the icon as needed */
  background-color: #78A083;
  /* Set the background color to the desired color */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  /* Set the icon text color to white */
  font-family: monospace;
  font-size: 16px;
  /* Adjust the font size of the icon text */
  position: relative;
  /* Change the position to relative */
  margin: 0 auto 20px;
  /* Center the icon horizontally and add margin to push the card content down */
}

/* Prevent text selection */
.feature-icon.code-icon span {
  user-select: none;
}

.feature-icon.code-icon i.gear-icon {
  font-size: 12px;
  /* Adjust the size of the gear icon */
}

/* General Styles */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  font-family: 'Quicksand', sans-serif;
  margin: 0;
  padding: 0;

  position: relative;
  background: linear-gradient(135deg, #e6c295, #f9e1b6);
  overflow: hidden;


}

/* Glassy Effect for Features Section */
.about-features {
  margin-top: 40px;
}

.about-feature {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  /* Transparent white background for features */
  text-align: center;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* About Section Styles */
.about-container {
  text-align: center;
  /*padding: 100px 0;
    
    background: linear-gradient(to right, white, #E0DFD5) !important;
    backdrop-filter: blur(10px); 
    border-radius: 8px;*/
  margin-bottom: 30px;
  background: linear-gradient(to right, white, #F2EFE5) !important;

  

}

/* About Section Styles */
.contact-container {
  text-align: center;
  padding: 100px 0;
  background-color: #f2f2f2;
  /* Set the background color to match the toolbar */
  backdrop-filter: blur(10px);
  /* Add the blur effect */
  border-radius: 8px;
  margin: 30px;
}



.about-title {
  font-family: 'Quicksand', sans-serif;
  font-size: 24px;
  /* Set the desired font size (adjust as needed) */
  margin-bottom: 20px;
  /* Optional: Add some spacing below the title */
}

.about-description {
  font-size: 30px;
  font-weight: 800;

  font-family: 'Quicksand', sans-serif;
  max-width: 600px;
  margin: 0 auto 40px;
  opacity: 0.9;
}

/* Hide the hamburger icon on desktop (wider screens) */
.v-btn.icon.menu-btn.mobile {
  display: none;
}


.software-process {
  padding-top: 10px; /* Only add top padding */
  display: flex;
  justify-content: center;
}

.software-process-intro {
  font-size: 20px !important;
  /* Set the font size */
  font-weight: 900;
  /* Set the font weight */
  font-family: 'SF Pro Display', sans-serif;
}

.software-process-title {
  font-size: 25px !important;
  /* Set the font size */
  font-weight: 900;
  /* Set the font weight */
  font-family: 'SF Pro Display', sans-serif;
}

.rounded-div {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  max-width: 600px; /* Adjust the width as needed */
}

@media (max-width: 768px) {
  .rounded-div {
    margin: 20px; /* Adds some space around the div on smaller screens */
  }
}


.vi-editor-card {
  background-color: #35374B !important;
  color: white!important;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 8px;
  overflow: hidden;
}

.mac-window-bar {
  background-color: #35374B;
  padding: 8px;
  display: flex;
  align-items: center;
}

.mac-circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.red {
  background-color: #ff605c;
}

.yellow {
  background-color: #ffbd44;
}

.green {
  background-color: #00ca4e;
}

.vi-editor {

  padding: 16px;
  
  white-space: pre-wrap; /* This will preserve the formatting but allow wrapping */
  word-wrap: break-word; /* Breaks long words to prevent overflow */
  overflow-wrap: break-word; /* Standardized property for word-wrap */

}





.timeline-container {
  max-width: 800px;
  /* Set the desired maximum width */
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  /* Center the container horizontally */
  background-color: black;
  color: white;
  /* Set the background color to white */
  border-radius: 8px;
  /* Add border-radius for rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* Add box shadow for the outline */
  padding: 20px;
  /* Add padding to the container */
  overflow: hidden;
  /* Clip any overflow content */

}

.sql-icon {
  font-size: 12px;
  /* Adjust the font size as needed */
}

.web-icon {
  font-size: 12px;
  /* Adjust the font size as needed */
}

/* not used currently - for underlining timeline titles when in focus
.underline {
  position: relative;
}

.underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 15%;  
  width: 30%;
  height: 1px;  
  background-color: grey;
}
*/


.v-timeline-item div {
  transition: opacity 0.3s;
}


.timeline-title-line {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  /* Adjust the spacing as needed */

  background: linear-gradient(to left, rgba(47, 135, 159, 0), #2F879F);
  background-size: 50% 1px, 50% 1px;
  background-position: left, right;
  background-repeat: no-repeat;
}

.timeline-title-line.left-gradient {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  background: linear-gradient(to right, rgba(47, 135, 159, 0), #2F879F);
  background-size: 50% 1px, 50% 1px;
  background-position: left, right;
  background-repeat: no-repeat;
}

.timeline-scroll {
  overflow-x: auto;
}

.timeline-mobile-padding {
  padding-bottom: 20px;
}

.mobile-spacer {
  margin-bottom: 40px;
}

.card-section {
  margin-top: 40px;
  /* Adjust the margin as needed */
  padding: 60px 0;
  background-color: #f9f9f9;
}

.custom-app-bar {
  /*background-color: #f2f2f2!important;  Use a slightly darker shade of grey, adjust as needed */
  font-family: 'San Francisco Pro Display', sans-serif !important;
  font-weight: 100 !important;
    background: linear-gradient(to right, white, #f0f0f0) !important;

}

.dark-app-bar {
  font-family: 'Quicksand', sans-serif;
  background-color: #082032 !important; /* Gradient from white to black for dark mode */
  color: white!important;
}

/* Center the Sections Container */
.sections-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0; /* Remove bottom margin */
  margin-top: 0; /* Ensure no top margin */
}

/* Scroll to Top Button Styles */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  /* Center the button horizontally */
  transform: translateX(-50%);
  /* Adjust the horizontal centering */
  background-color: rgba(0, 0, 0, 0.3);
  /* Set the background color to a less noticeable one */
  color: white;
  /* Set the font color to white */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0.7;
  /* Reduce the opacity to make it less noticeable */
}

.scroll-to-top:hover {
  opacity: 1;
  /* Restore opacity on hover */
}


/* Adjusted height for the larger menu */
.full-height.large {

  position: fixed;
  /* Set the menu to fixed position */
  top: 0;
  /* Fix the menu at the top of the viewport */
  left: 0;
  /* Align the menu to the left edge */
  z-index: 1000;
}

.blur-out {
  filter: blur(5px);
  /* Adjust the blur intensity as needed */
  pointer-events: none;
  /* Prevent interaction with the blurred content */
  transition: filter 0.3s ease-in-out;
  /* Add a smooth transition */
}

/* Full Width Indigo Section */
.full-width-indigo-section {
  width: 100%;
  /*height: 100vh;  Full height of viewport */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}






/*with arrow white pointing down into the indigo*/
.indigo-background {
  /*background-color: #50727B;*/
  background: linear-gradient(to right, #50727B 0%, #344955 100%);
  width: 98%;
  /* 3x larger width */
  /*height: 70vh;  3x larger height */

  padding-top: 40px;
  /* Adjust padding to make space for the triangle */
  padding-bottom: 20px;
  border-radius: 8px;

  box-sizing: border-box;
  text-align: center;
  color: white;
  /* Set text color */
  position: relative;
  /* Add this line to create a positioning context for the pseudo-element */

}


.indigo-background::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  /*border-top: 20px solid white; This time, we use border-top to create the downward triangle and make it white */
  position: absolute;
  top: 0;
  /* Position it right at the top edge */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  /* Ensures the triangle is above other content */
}

/* indigo arrow pointing up into the white */
/*
.indigo-background {
  background-color: #14566B;
  width: 98%;
  padding: 20px; 
  border-radius: 8px;
  box-sizing: border-box;
  text-align: center;
  color: white; 
  position: relative; 
}

.indigo-background::before {
  content: ''; 
  width: 0;
  height: 0;
  border-left: 20px solid transparent; 
  border-right: 20px solid transparent; 
  border-bottom: 20px solid #14566B; 
  position: absolute;  
  top: -20px; 
  left: 50%; 
  transform: translateX(-50%); 
}

*/



.indigo-content {
  max-width: 800px;
  /* Adjust max-width as needed */
  margin: 0 auto;
}


/* these are not proper carousel arrow classes but since z-index of centered-content::before (which is for background image)  
is 1, for arrows to be visible above it they should have higher z-index. 
If centered-content::before would have negative number then it would be invisible (pushed behind everything). it should have  
z-index though so that opacity of background doesnt apply to centered-content text, only to image */
.v-window__next,
.v-window__prev {
  z-index: 3;
  /* Adjust as needed. Just make sure it's higher than the z-index of your content. */
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  /* To make sure the ::before element is relative to this container */
}

.centered-content::before {
  content: "";
  background-image: url('~@/assets/database.png'), url('~@/assets/server.png');
  background-position: left top, right;
  /* Adjust the positions as needed */
  background-repeat: no-repeat, no-repeat;

  background-size: 12%, 8%;
  opacity: 0.04;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  /* To ensure the image stays behind the content */
}

@media (max-width: 768px) {
  .centered-content::before {
    background-size: 150px 120px;
    /* You can adjust this value to your desired fixed size for mobile */
  }
}

.full-width-indigo-section .v-btn .v-btn__content .v-icon {
  color: rgba(255, 255, 255, 0.3);
  /* Opaque white color with 70% opacity */

}




@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.indigo-title-slide-in {
  animation: slideFromLeft 1s forwards;
}



.monitors-and-dots {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}



.horizontal-dots {
  margin: 0 10px;
  /* Adjust spacing between dots */
  font-size: 24px;
}

.indigo-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.indigo-text {
  font-size: 16px;
}

.monitor-outline {
  border: 1.5px solid white;
  border-radius: 10px;
}

.horizontal-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.dot {
  width: 1px;
  height: 1px;
  background-color: white;
  border-radius: 50%;
  margin: 0 5px;
  opacity: 0.5;
  animation: dotAnimation 1s infinite;
}

@keyframes dotAnimation {

  0%,
  100% {
    opacity: 0.5;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.5);
    background-color: white;
  }
}



.code-line {
  height: 1px;
  background-color: white;

  margin: 5px 3px;
  /* Adjust spacing between lines */
}





/* Modern Footer Styles */
.modern-footer {
  background-color: #2f879f !important;
  color: white !important;
  padding: 40px 0;
  text-align: center;
  flex-shrink: 0;
}

.footer-section {
  margin-bottom: 20px;
}

.footer-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.footer-content {
  font-size: 16px;
  opacity: 0.8;
}

.social-icons {
  margin-top: 20px;
  text-decoration: none;
  /* Remove underlining */

}

.social-icon {
  color: #ffffff;
  /* Set the icon color */
  text-decoration: none !important;
  /* Remove underlining */
  margin: 0 10px;
  transition: color 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .footer-section {
    margin-bottom: 10px;
    text-align: center;
    /* centers the content on smaller screens */
  }

  .footer-title,
  .footer-content {
    margin-left: 10px;
    margin-right: 10px;
  }
}




/* Black Section Styles */
.black-section {
  background-color: black;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Circles Container */
.circles-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Circle Styles */
.circle {
  width: 140px;
  height: 140px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  /* Add margin between the circles */
  position: relative;
}

/* Circle Content Styles */
.circle-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Circle Text Styles */
.circle-text {
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-align: center;
}

/* Diagonal Line Styles */
.circle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: rotate(45deg);
  bottom: -1px;
  left: 0;
}





.monitor-container {
  display: flex;
  justify-content: space-between;
}

.monitor {
  position: relative;
  width: 400px;
  height: 300px;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
}

.monitor:first-child {
  opacity: 0.9;
  /* Adjust the opacity value for the first monitor */
}

.monitor:nth-child(2) {
  background-color: #333;
}

.window-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Adjust this to your needs */
}

.window-buttons {
  display: flex;
}

.window-button {
  width: 12px;
  height: 12px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: white;
  /* Adding background color for dots */
}

.toolbar-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ccc;
  margin-left: 5px;
}

.toolbar-dot:nth-child(1) {
  background-color: orange;
}

.toolbar-dot:nth-child(2) {
  background-color: yellow;
}

.toolbar-dot:nth-child(3) {
  background-color: green;
}

/* Your existing styles... */

/* Your existing styles... */

.window-lines {
  position: absolute;
  /*top: 20px;  Adjust the top margin as needed */
  left: 5%;
  /*width: 50%;*/
  height: 4px;
  /* Adjust the line height as needed */
  display: flex;
  align-items: center;
}

.line-start {
  color: white;
  font-size: 10px;
  /* Adjust the font size as needed */
  margin-right: 5px;
  /* Adjust the margin as needed */
}

.line {
  flex-grow: 1;
  height: 50%;
  width: 10% !important;
  /* Adjust the line length as needed */
  background-color: rgba(255, 255, 255, 0.5);
  /* Adjust the opacity as needed */
  border-radius: 2px;
}

.line-start,
.line {
  pointer-events: none;
}

.single-line {
  position: absolute;
  top: 60px;
  /*Adjust the top margin as needed */
  left: 10%;
  width: 50%;
  height: 2px;
  /* Adjust the line height as needed */
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.4);
  /* Adjust the opacity as needed */
  border-radius: 2px;
}

.single-line2 {
  position: absolute;
  top: 60px;
  /*Adjust the top margin as needed */
  left: 10%;
  width: 50%;
  height: 2px;
  /* Adjust the line height as needed */
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.2);
  /* Adjust the opacity as needed */
  border-radius: 2px;
}

.database-icon {
  width: 200px;
  height: 200px;
  background-color: #325e7c;
  border-radius: 50%;
  position: relative;
}

.layer {
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.layer-1 {
  background-color: #1c416f;
  z-index: 3;
}

.layer-2 {
  background-color: #204a86;
  z-index: 2;
}

.layer-3 {
  background-color: #23538f;
  z-index: 1;
}


/*flyer*/


/*.flyer-content-closing {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;  
  transform: translateY(50px);  
  opacity: 0;
}*/

.flyer-container {
  perspective: 1500px;
}

.folded:first-child {
  transform: rotateY(90deg);
  /* Rotate towards the right border (page 2) */
  -webkit-transform: rotateY(90deg);
  /* Add -webkit-transform for Chrome */
  transition: transform 0.5s ease-out;
  -webkit-transition: -webkit-transform 0.5s ease-out;
  /* Add -webkit-transition for Chrome */
  box-shadow: -10px 0 15px rgba(0, 0, 0, 0.5);
}



.flyer-content-closing-folded {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out, box-shadow 0.5s ease-out;
  transform: translateY(30px);
  opacity: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.flyer-content-closing {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  transform: translateY(50px);
  opacity: 0;
}



.centered-image-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  /* Add top margin for spacing */
}

/* Add this CSS to your styles */
.image-containers {
  max-width: 100px;
  /* Adjust the maximum width as needed */

  margin-top: 15px;
  /* Adjust the margin as needed */
}

.smaller-image {
  max-width: 100%;
  /* Adjust the maximum width as needed */
  height: auto;
  /* Maintain aspect ratio */
}


.flyer-container {
  width: 80%;
  margin: 0 auto;
  min-height: 300px;
  /* Allows container to grow based on content */
  display: flex;
  justify-content: center;
  align-items: center;
  /* This will vertically center the cover */
  flex-direction: column;
  /* Stacks child elements vertically */
  padding-bottom: 40px;
  /* Provides spacing at the bottom */

}

/* Remove the fill-height class from <v-row> in the Cover section. It forces 100% height, which we don't want when using the flex approach */


.flyer-content {
  padding: 20px;
  /* Adjust this value as needed */

}

/*
.folded {
  transform: scaleX(0);
  transition: transform 1s;

      transform: rotateY(90deg);

}

.flyer-cover {
    width: 100%;
    height: 100%;
    background: #ddd;
    text-align: center;
    line-height: 300px;
    position: absolute;
    z-index: 5; 

}

*/

.folded {
  transform: scaleX(0);
  transition: transform 1s;
}



.page-2 .close-flyer-btn {
  display: none;
  /* Initially hidden */
}




/*

.page {
  transform-style: preserve-3d;
    transition: transform 1s ease-out;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    backface-visibility: hidden; 


}


.folded:first-child {
    transform: rotateY(-90deg);
}

.folded:last-child {
    transform: rotateY(90deg);
}

.open-flyer-container {
    perspective: 1200px;
}
*/



.back-link {
  cursor: pointer;
  color: white;
  /* You can choose any color to make it visible against the background */
  padding: 10px 0;
  /* Add some space above and below */
  font-weight: bold;
  /* Optional: To make the text bold */
}



/*
.flyer-wrapper.full-height {
  height: calc(100vh - 56px); 
  width: 100%;
}

.flyer-container.open {
  height: 100%;
  width: 100%;
}

.open-flyer-container {
  height: 100%;
}

.full-height-row {
  height: 100%;
}

.page {
  height: 100%; 
} */

.no-select {
  user-select: none !important;
  /* Standard syntax */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10+ */
  pointer-events: none;
}



.full-size-flyer .page {
  height: 100vh;
  /* This makes each page as tall as the viewport */
  /* Other styles as required */
}

.flyer-row>.v-col {
  width: 33.33%;
  /* When the flyer is open, each page (v-col) will take up a third of the width */
  min-height: 100%;
  /* This will ensure each column fills the height of the row */
  transition: width 0.3s ease;
  /* Transition effect for smooth unfolding */
}

/* Styles for the flyer pages on mobile */
@media (max-width: 767px) {
  .full-height-row {
    height: auto;
    /* or min-height: 100%; to ensure it takes up at least the full height */
    overflow: visible;
    /* Ensure that overflowing content is visible */
  }
}


.footer-link {
  text-decoration: none;
  color: inherit;
  /* assuming you want to keep the original color */
}

.footer-link:hover {
  cursor: pointer;
}

.blank-section {
  margin-top: 20px;
  margin-bottom: 20px;


}

.p-background {
  position: relative;
  padding: 20px;
  /* Add some padding for spacing */
}

.p-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/dippen.jpg");
  /* Replace with the correct path to your image */
  background-size: contain;
  /* Adjust as needed */
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  /* Adjust the opacity as needed */
  z-index: -1;
  border-radius: 5px;
  /* Optional: Add rounded corners */
  background-size: 60%;
}


.underline-style:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 0;
  left: 25%;
  border-bottom: 1px solid #04724D;
}



.blank-section.with-border {
  border-top: 1px solid transparent;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: center top;
}


/*
 .image-container-cent {
    text-align: center; 
     
  }

  .image-container img {
    max-width: 50%; 
    height: auto; 
    display: block;
    margin: 0 auto; 
  
  }
  */

.adjusted-image {
  position: relative;
  top: 120px;
  /* Adjust this value to move the image vertically down */
  max-width: 50%;
  /* Adjust as needed */
  height: auto;
}

.adjusted-image-wall {
  top: 100px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 60%;
  height: auto;
}


.image-container {
  text-align: center;
  padding: 20px;
}

.adjusted-image-athena {
  top: 100px;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 40%;
  height: auto;
}


.image-adjust {
  width: 100% !important;
  /* Adjust as needed */
  height: auto !important;
}

.rounded-image {
  border-radius: 20px;
  /* Adjust the radius to your preference */
  /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
  opacity: 0.8;
  /* Adjust opacity as needed */

}








.centered-carousel .v-carousel-item {
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  /* Vertical centering */
}


/* Make the carousel arrows and controls opaque 
.v-carousel .v-btn::before {
  opacity: 0.07; 
  z-index: 2;
}
*/

/* Make the carousel arrows and controls opaque on hover */
.v-carousel:hover .v-btn::before {
  opacity: 1;
  /* Adjust the opacity on hover as needed */
}

/* Style the bottom action bar */
.bottom-action-bar {
  /*background-color: white;  Background color of the action bar */
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px;
  /*Adjust padding as needed */
  text-align: center;
  /* Center the text horizontally */
  color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #ccc;
  /* Adjust the color to a lighter grey */
  font-size: 13px;
  border-radius: 0 0 10px 10px;
  /* Add rounded corners to the bottom */


}

.action-link {
  text-decoration: none;
  /* Remove default underline */
  transition: text-decoration 0.3s;
  /* Add transition for smooth hover effect */
  color: black;
}

.action-link:hover {
  text-decoration: underline;
  /* Underline on hover */
}

.feature-content {
  /* Add some margin to the bottom of the feature content to make space between content text and action bar*/
  margin-bottom: 60px;
  /* Adjust the margin as needed */
}

.icon-space {
  margin-right: 10px;
  /* Adjust the margin to control the spacing between icons */
}



.full-width-bar {
  background-color: white;
  width: 100%;
  padding: 20px 0;
  overflow: hidden;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align icons to the start of the container */
  align-items: center; /* Center icons vertically */
  gap: 10px; /* This replaces margin-right from .icon-space and adds vertical gap */
}

.icon-row {
  padding-left: 25%; /* Add left padding to the entire row */
}

/* Center icons in .icon-container on smaller screens */
@media (max-width: 600px) {
  .icon-container {
    justify-content: center; /* Center icons horizontally when they wrap */
  }
}

.icon-row {
  padding-left: 25%; /* Add left padding to the entire row */
  width: 100%; /* Ensure the row takes full width to allow centering within it */
}

@media (max-width: 600px) {
  .icon-row {
    padding-left: 0; /* Remove left padding on smaller screens */
    justify-content: center; /* Ensures icons are centered on mobile screens */
  }
}

.bar-icon {
 
  flex: 0 1 auto; /* Do not grow, do not shrink, based on content size */
  max-height: 30px;
  }



  .bar-icon-bsd {
 
 flex: 0 1 auto; /* Do not grow, do not shrink, based on content size */
 max-height: 40px;
 }

 .bar-icon-illumos {
 
 flex: 0 1 auto; /* Do not grow, do not shrink, based on content size */
 max-height: 40px;
 }

/*
@media (max-width: 768px) {
  .bar-icon {
    max-height: 20px;  If you want to keep the icons' size, consider removing this rule 
  }
}*/
/* Default font size for larger screens */
.gradient-text {
  user-select: none; 
  font-size: 16px;
  font-weight: 900;
  /* Set the font weight */
  font-family: 'SF Pro Display', sans-serif;
  /* Adjust the font size as needed */
}

/* Font size for smaller screens (e.g., mobile) */
@media (max-width: 768px) {
  .gradient-text {
    font-size: 14px;
    /* Adjust the font size as needed for smaller screens */
  }
}






/*
@keyframes slideIcons {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}*/

.v-card {
  max-width: 500px;
  /* Limit the width for a more card-like appearance */
  margin: 0 auto;
  /* Center the card in the container */
  border: 1px solid #000;
  /* Add a simple border to the card */
}

.transparent-input .v-input__slot {
  background-color: transparent !important;
}

.background-overlay::before {
  content: '';
  background-image: url('@/assets/mcu.png');
  background-size: contain;
  background-position: center center;
  opacity: 0.03;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.background-overlay {
  position: relative;
}</style>