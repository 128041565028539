import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../HomePage.vue'; // Adjusted path
import AboutPage from '../AboutPage.vue'; // Adjusted path

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
    },
    {
      path: '/about',
      name: 'About',
      component: AboutPage,
    },
  ],

  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
