import { createApp } from 'vue'
import App from './App.vue'
//import PrismicVue from '@prismicio/vue';
import { createPrismic } from '@prismicio/vue';
//import { PrismicClient } from '@prismicio/client';
import router from './router/router'; // Adjust this path if your router.js is located elsewhere


// Prismic setup
const endpoint = 'https://ithas.cdn.prismic.io/api/v2';

const prismic = createPrismic({
    endpoint: endpoint,
    //client: PrismicClient(endpoint),
});
/*const routes = [
    { type: 'page', path: '/:uid' },
    { type: 'page', uid: 'homepage', path: '/' }
];*/

export default prismic;

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// main.js or main.ts
import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.min.css'; // Import the Material Design Icons CSS



const vuetify = createVuetify({
  components,
  directives,
})

/*
createApp(App)
  .use(vuetify)
  .use(PrismicVue, {
    endpoint,
    apiOptions: { routes },
  })
  .mount('#app');*/

//Create Vue app
/*
const app = createApp(App);

app.use(vuetify);                   // Use Vuetify
app.config.globalProperties.$prismic = prismic; // Set Prismic to global properties
app.mount('#app');       
*/

const app = createApp(App);
app.use(vuetify, {
  theme: {
    dark: true, // Set this to true to enable dark mode initially
  },
});                   // Use Vuetify
app.use(prismic);                   // Use Prismic
app.use(router);
app.mount('#app');
