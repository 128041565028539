<template>
  <v-app-bar density="comfortable" rounded ref="appBar" :elevation="1" :class="{'custom-app-bar': !isDarkMode, 'dark-app-bar': isDarkMode}" app>
    <!--class="custom-app-bar"-->
    <!-- Logo -->
    <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->


    <img src="./assets/logo.png" alt="Logo" :class="{'logo-image': true, 'dark-mode-logo': isDarkMode}" />


    <!-- Normal Menu Entries (shown on desktop-sized screens) -->
    <div class="normal-menu-entries" v-if="!isMobile">
      <router-link to="/" class="menu-entry">Home</router-link>
      <router-link to="/about" :class="['menu-entry', { 'menu-entry-dark': isDarkMode }]">About</router-link>
      <a class="menu-entry" @click="navigate($event, 'contact')" role="button">Contact</a>
    </div>


    <v-spacer></v-spacer>


    <!-- Hamburger Icon -->
    <v-btn ref="menuToggle" id="menuToggle" icon class="menu-btn mobile" @click="toggleMobileMenu" v-if="isMobile">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <!-- Dummy Menu with Hardcoded Entries -->
    <v-menu transition="scale-transition" v-model="showMobileMenu" :close-on-content-click="false"  class="custom-menu-position" height="400px" width="100vw" :value="showMenu">

      
      <v-list >

    
       <!-- <v-list-item>
            <v-switch
              v-model="hints"
              color="purple"
              label="Enable hints"
              hide-details
            ></v-switch>
          </v-list-item>-->
    <!-- Home Link -->
    <v-list-item >
      <v-list-item-title>
        <router-link to="/" class="menu-link">Home</router-link>
      </v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <!-- About Link -->
    <v-list-item >
      <v-list-item-title>
        <router-link to="/about" class="menu-link">About</router-link>
      </v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <!-- Contact Link -->
    <v-list-item >
      <v-list-item-title @click="navigate('contact')" class="menu-link">Contact</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
          Explore our website to learn more about us!
        
      </v-list-item>

      <v-btn
            variant="text"
            @click="menu = false"
          >
            Close
          </v-btn>
  </v-list>
  

    </v-menu>


    </v-app-bar>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
    isDarkMode: Boolean,
    menuEntries: Array,
    showMenu: Boolean,
    // showMobileMenu: false, // Controls the visibility of the mobile menu

    // Add any other props you need
  },
  data() {
    return {
      showMobileMenu: false, // Local state to control the visibility of the mobile menu
    };
  },
  methods: {


    handleBodyClick(event) {
      
    // Check if the click target is not within the menu or menu toggle button
    if (
    !this.$el.contains(event.target) &&
    !this.$refs.appBar.$el.contains(event.target) &&
    event.target.id !== 'menuToggle'
  ) {
      // Close the menu
      this.showMobileMenu = false;
      this.$emit('toggle-menu', this.showMobileMenu); // Emit the event to inform the parent component
    }
  },
    toggleMenu() {
      this.$emit('toggle-menu', this.showMobileMenu);
      //console.log('Toggle menu event emitted with showMenu:', this.showMobileMenu);

    },
    toggleMobileMenu() {
     
      this.showMobileMenu = !this.showMobileMenu;
      this.$emit('toggle-menu', this.showMobileMenu);
      //console.log('Toggle menu event emitted with showMenu:', this.showMobileMenu);

    },
    selectMenuItem() {
    this.showMobileMenu = false; // Close the menu and unblur the app
    this.$emit('toggle-menu', false); // Ensure to communicate the menu is being closed

  },


    // Include any other methods or utilize the props as needed within AppBar
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    navigate(arg, link = null) {
    let actualLink = link;
    if (typeof arg === 'string') {
      // If the first argument is a string, then it's being called from the mobile menu without an event
      actualLink = arg;
    } else if (arg && typeof arg.preventDefault === 'function') {
      // If the first argument has preventDefault, then it's an event object
      arg.preventDefault();
    }

    if (actualLink === 'contact') {
      window.location.href = 'mailto:contact@ithas-site.com';
    } else if (actualLink) {
      // Navigate using Vue Router for internal links
      this.$router.push({ name: actualLink }); // Adjust based on your routing setup
    }

    // Close the mobile menu after navigation
    this.showMobileMenu = false; 
    this.$emit('close-menu'); // Optionally emit an event to handle app-wide effects like unblurring
  },
  },

  created() {
  // Attach event listener to handle clicks outside of the menu
  document.addEventListener('click', this.handleBodyClick);
},
unmounted() {
  // Remember to remove the event listener when the component is destroyed
  document.removeEventListener('click', this.handleBodyClick);
},
}
</script>

<style>

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 60%;
  /* Set the desired height relative to the parent container */
  width: auto;
  padding-left: 35px !important;
  /* Move the logo 50px to the right */

}

.menu-entries {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-entry {
  font-size: 16px;
  font-weight: bold;
  margin-left: 16px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: black;
  /* Set the font color to #14566B */
}

.menu-entry-dark {
  color: white; /* Color for dark mode */
}

.menu-entry::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background: none;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.menu-entry:hover::before {
  visibility: visible;
  transform: scaleX(1);
  background: linear-gradient(to right, gold, goldenrod);
}

.full-height.large {

  position: fixed;
  /* Set the menu to fixed position */
  top: 0;
  /* Fix the menu at the top of the viewport */
  left: 0;
  /* Align the menu to the left edge */
  z-index: 1000;
}

.custom-menu-position {
  position: fixed;
  top: 0px; /* Adjust this value based on the height of your app bar */
  right: 0;
  z-index: 1000;
}

.menu-close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 4px; /* Adjust padding to change button size */
}

.menu-close-icon {
  font-size: 16px; /* Adjust font size to change icon size */
}

.menu-link {
  text-decoration: none; /* Remove hyperlink underline */
  color: inherit; /* Inherit color from parent */
  transition: color 0.3s, background-color 0.3s; /* Add transition for smooth hover effect */
}

.menu-link:hover {
  color: inherit; /* Maintain the same color on hover */
  cursor: pointer!important;

 }

 .menu-subtext {
  font-size: 12px;
  color: #888;
  margin-top: 10px; /* Adjust as needed */
}
</style>