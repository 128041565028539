<template>
  <v-app :class="{ 'blur-out': showMenu }" app>
    <AppBar :is-mobile="isMobile" :is-dark-mode="isDarkMode" :menu-entries="menuEntries" :show-menu="showMenu" @toggle-menu="handleToggleMenu" @navigate="navigate" @close-menu="showMenu = false" />

    <v-main>

      <!-- Wrapper Container for Sections -->
      <div class="sections-container">

        <!-- About Section -->
        <div class="about-container" id="about">
          <v-container class="hero-container" :class="{'dark-mode-bg': isDarkMode}" fluid>
            <v-row align="center" class="hero-row">
              <v-col cols="12" md="4" class="text-center" :offset-md="3">
                <h1 :class="{'gradient-text-artofcode': !isDarkMode, 'gradient-text-artofcode-dark': isDarkMode}">Art of Code</h1>
                <p class="paragraph-text-left mt-2 mb-4" :style="isDarkMode ? 'color: white;' : ''"><i><b>ITHAS</b></i> brings you a unique blend of services and
                  products designed to elevate your business and ventures to new heights. From expert consulting in
                  timeless technologies, to fostering innovation and virtuosity in the realm of computing.</p>
                <v-btn color="teal lighten-1" to="/about" style="font-weight: bold;">Learn More&nbsp; <i class="fas fa-arrow-right"></i></v-btn>
                    

              </v-col>
              <!-- Adjusted image column -->
              <v-col cols="12" md="4" class="text-left" :offset-md="0">
                <img src="@/assets/hero-main.png" alt="hero" style="width: 100%; max-width: 350px;" />
              </v-col>
            </v-row>
          </v-container>

         



        </div>

      </div>

      <div>
  <div class="full-width-bar">
    <p class="gradient-text" style="text-align:left;color:grey;margin-left:10px;padding-bottom:5px;font-weight: bold;font-size:17px;">
      <b>inspired by the best in technology</b>
    </p>
    <v-row justify="center" class="icon-row">
  <v-col cols="12" sm="6" md="4" lg="2" class="icon-column" v-for="n in 7" :key="n">
        <div class="icon-container">
          <img src="@/assets/illumos.png" alt="illumos" class="bar-icon-illumos" v-if="n === 1"/>
          <img src="@/assets/opensolaris.png" alt="opensolaris" class="bar-icon" v-if="n === 2"/>
          <img src="@/assets/freebsd.png" alt="freebsd" class="bar-icon-bsd" v-if="n === 3"/>
          <img src="@/assets/zfs.png" alt="zfs" class="bar-icon" v-if="n === 4"/>
          <!--<img src="@/assets/vue.png" alt="vue" class="bar-icon" v-if="n === 5"/>-->
          <!--<img src="@/assets/node.png" alt="node" class="bar-icon" v-if="n === 6"/>-->
          <img src="@/assets/ccpp.png" alt="ccpp" class="bar-icon" v-if="n === 5"/>
        </div>
      </v-col>
    </v-row>
  </div>
</div>




      <v-container fluid class="partners-section dark-bg">
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <h4 class="partners-heading">PAST AND CURRENT PARTNERS</h4>
          </v-col>
        </v-row>
        <v-row justify="center">
          <!-- Repeat v-col for each partner logo -->
          <v-col cols="6" sm="4" md="3" lg="2" class="d-flex justify-center py-2">
            <img src="@/assets/agilab.png" alt="Agilab" class="partner-image" />

          </v-col>
          <!-- Add more v-col elements for additional logos as needed -->
          <v-col cols="6" sm="4" md="3" lg="2" class="d-flex justify-center py-2">
            <img src="@/assets/rempar.png" alt="Rempar" class="partner-image" />

          </v-col>

          <v-col cols="6" sm="2" md="2" lg="1" class="d-flex justify-center py-2">
            <img src="@/assets/ion.png" alt="Ion" class="partner-image " />

          </v-col>

          <v-col cols="6" sm="4" md="3" lg="2" class="d-flex justify-center py-2">
            <img src="@/assets/apexers.svg" alt="Apexers" class="partner-image" />

          </v-col>

        </v-row>
      </v-container>


    <!--  
<div class="timeline-container" :class="{ 'timeline-mobile-padding': mobileScreen }" id="portfolio">
  <div class="timeline-scroll" ref="timelineScroll">
   <v-timeline align="start" :side="isMobile ? 'end' : undefined" >
    <v-timeline-item
    ref="timelineItems"
    :data-id="item.id || index"
     v-for="(item, index) in timelineItems"
    :key="index"
    :dot-color="black"
    size="large"
    >
  <template v-slot:icon>
      <span class="timeline-dot-content" ref="timelineIcons">
     <b v-if="item.title === 'Oracle Database'" class="sql-icon no-select">SQL&gt;</b>
    <b v-else-if="item.title === 'Web Development'" class="no-select">&lt;/&gt;</b>
    <b v-else class="no-select">>_</b>
      </span>      </template>

      <template v-slot:opposite>
      <div :class="`pt-1 headline font-weight-bold text-${item.color}`" ref="timelineTitles">
        <div :style="{ fontSize: isMobile ? '12px' : 'inherit' }">{{ item.title }}</div>
        <div :class="{'timeline-title-line': true, 'left-gradient': index % 2 === 0}" :style="{ fontSize: isMobile ? '10px' : 'inherit' }"></div>

      </div>
      </template>
      <div>
       <h2 :class="`mt-n1 headline font-weight-light mb-4 text-${item.color} ${index === focusedIndex ? 'underline' : ''}`" :style="{ opacity: (index === focusedIndex) ? '1' : '0.5' }">
        {{ item.title }}
      </h2>
      <div>
        <div v-html="item.content" :style="{ fontSize: isMobile ? '12px' : 'inherit', opacity: (index === focusedIndex) ? '1' : '0.5' }"></div> 
      </div>
      </div>
    </v-timeline-item>
  </v-timeline>
  </div>
  </div>
-->


<!--<v-container fluid class="diagonal-split-section" >
    <v-row>
      <v-col cols="12">
      </v-col>
    </v-row>
  </v-container>-->
 

  <div class="first-container">
    <div class="content-inner">

      <div class="software-process">
        <div class="software-process-intro" style="background-color: white; padding: 20px; margin: 10px; opacity:0.9;">
          <div class="software-process-intro-text"> Building Software with Purpose. <i>Blending functionality with the essence of creativity to serve 
            the spectrum of personal and professional pursuits with elegance and insight, guided by the conviction that software should enrich our lives.</i> 
          </div>
</div>
</div>
      <div class="software-process">
  <div class="rounded-div">
    <!--<div class="software-process-title">Markets</div>-->
    <p><b>Need Fast Deployment?</b> Ditch the linux-centric Docker files. Instead of distancing your application from native operating system and 
      running environment, opt for a deployment strategy that ensures the underlying system understands and optimizes 
      every aspect of your application's deployment, including security and handling dependencies.
    </p> 
    <br />
    <p><b>Crave Real-Time Monitoring? </b> Kubernetes' monitoring can seem indispensable, until you meet DTrace. 
      Gain unparalleled insights into application performance and system behavior, with enabled proactive optimization 
      without navigating a maze of dashboards.</p>
  </div>
</div>

  <!--
<v-card class="vi-editor-card ma-5">
      <div class="mac-window-bar">
        <div class="mac-circle red"></div>
        <div class="mac-circle yellow"></div>
        <div class="mac-circle green"></div>
      </div>
      <v-card-text class="vi-editor">
        <div>~ Lorem ipsum dolor sit amet... asdasdafsdf sd sdd fdsf sdf sd dsfs</div>
        <div>~ </div>

      </v-card-text>
    </v-card>
  -->
  
    <!--  <div class="timeline-wrapper">

    
<v-timeline align="start" id="timeline">
  <v-timeline-item
  dot-color="black"
      size="x-small"
      fill-dot>
    <template v-slot:opposite >
      <h3>Markets</h3>
    </template>
    <v-card>
        <v-card-title :class="['text-h6']" >
          Test 
        </v-card-title>
        <v-card-text class="bg-white text--primary">
          <p>Experience in financial, health, and educational sectors..</p>
        
        </v-card-text>
      </v-card>
  </v-timeline-item>

  <v-timeline-item
  dot-color="black"
      size="small"
      fill-dot>
    <template v-slot:opposite>
      Opposite content
    </template>
    <v-card>
        <v-card-title >
          Lorem Ipsum Dolor
        </v-card-title>
        <v-card-text class="bg-white text--primary">
          <p>Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.</p>
        
        </v-card-text>
      </v-card>
  </v-timeline-item>

  <v-timeline-item>
    <template v-slot:opposite>
      Opposite content
    </template>
    <div>
      <div class="text-h6">Content title</div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </p>
    </div>
  </v-timeline-item>
</v-timeline>
     </div> -->
  </div>
</div>


<div class="new-container" style="background-color: #1a1d1e; border-top: 2px solid #4f4f4f">
  <div class="container-inner" style="display: flex; align-items: center; justify-content: space-between; padding: 20px; color: white;">
    <div class="left-half" style="flex: 1; display: flex; align-items: center; justify-content: center;">
      <h2 class="gradient-text-hardware-title">Scaling with hardware</h2>
    </div>
    <div class="right-half" style="flex: 1; padding: 20px; color: white;">
      <p>Why not set a higher standard? <span class="inner-hardware" style="color: #dbd9d9;">From servers and clients to mobile and embedded devices—whether in healthcare, finance, education, multimedia, or entertainment—you 
        deserve applications that benefit from hardware and architecture advancements.<br/>
        In a world where tech is veiled by marketing fluff - what millions of clients, customers, and markets out there truly need 
        is portable, interoperable, secure, stable and optimized software. <br/><br/> <span class="gradient-text-hardware">At ITHAS, a job well done is not just stacking upon cookie-cutter frameworks. 
        It's about products that resonate with understanding of the silicon upon which they run. </span></span></p>
    </div>
  </div>
</div>

<div class="new-container-second" >
  <!-- Upper half with specific background color -->
  <div class="container-inner" style="background-color: #F2EFE5; padding: 20px; color: black;">
    <div style="display: flex; align-items: center; justify-content: space-between;">
      <div class="left-half" style="flex: 1; display: flex; align-items: center; justify-content: center;">
        <h2>Web applications</h2>
      </div>
      <div class="right-half" style="flex: 1;">
        <p>
          Suite of web development services <span class="pro-display-100">designed to cater to the foundational needs of standard web architectures, 
          accommodating the specific demands of Vue.js or React ecosystems. <br/>
          Full web application capabilities, with a backend of choice, such as Node.js or even serverless architectures—a term that 
          cleverly disguises the fact that servers are indeed used, but abstracting away direct server management. </span> <br/><br/>
          <span class="pro-display-800">Every project can achieve its full potential, 
            <span style="color: #50727B;">backed by a technology stack</span><span style="color: #78A083;"> 
              that's customized for its specific needs and objectives.</span>
</span>
          </p>
      </div>
    </div>
  </div>
  <!-- Lower half with white background for dummy text -->
  <div class="pro-display-100" style="background-color: none; padding: 20px; padding-bottom:8%; padding-left: 50px; padding-right: 50px; color: black;">
    <p> ITHAS offers a partnership that encompasses the full lifecycle of your project, from the spark of an idea to its final deployment and beyond
      Complete automation, configuration management, and support across all stages, in order to free you from the technical complexities, 
      allowing you to focus on your vision</p>
  </div>
</div>








      <!-- Add a div with margin-bottom for spacing , needed for normal not just mobile - NO, NOT NEEDED NOW-->
      <!--<div class="mobile-spacer"></div>-->

      <!-- <FullWidthSection :isVisible="showFullWidthSection"  /> -->
      <div class="full-width-indigo-section">
        <div class="indigo-background">
          <div class="centered-content">
            <div class="monitors-and-dots">
              <div class="monitor monitor-outline" style="width: 120px; height: 80px;">
                <div class="window-bar">
                  <div class="window-buttons">
                    <span class="window-button toolbar-dot"></span>
                    <span class="window-button toolbar-dot"></span>
                    <span class="window-button toolbar-dot"></span>
                  </div>
                  <div class="window-lines" style="top:20px; width:50%;">
                    <div class="line-start">$</div> <!-- $ character before the line -->
                    <div class="line"></div> <!-- Horizontal line under the window bar -->

                  </div>

                  <div class="window-lines" style="top:35px; width:70%;">
                    <div class="line-start">$</div> <!-- $ character before the line -->
                    <div class="line"></div> <!-- Horizontal line under the window bar -->

                  </div>

                  <div class="single-line" style="top:50px; width:35%; left:14%;"></div>
                  <div class="single-line" style="top:50px; width:20%; left:55%;"></div>
                  <div class="single-line2" style="top:60px; width:40%; left:14%;"></div>
                  <div class="single-line2" style="top:60px; width:25%; left:60%;"></div>
                </div>
              </div>
              <div class="horizontal-dots">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </div>
              <div class="monitor monitor-outline" style="width: 120px; height: 80px;">
                <div class="window-bar">
                  <div class="window-buttons">
                    <span class="window-button toolbar-dot"></span>
                    <span class="window-button toolbar-dot"></span>
                    <span class="window-button toolbar-dot"></span>
                  </div>

                  <div class="window-lines" style="top:20px; width:50%;">
                    <div class="line-start">$</div> <!-- $ character before the line -->
                    <div class="line"></div> <!-- Horizontal line under the window bar -->

                  </div>

                  <div class="window-lines" style="top:35px; width:70%;">
                    <div class="line-start">$</div> <!-- $ character before the line -->
                    <div class="line"></div> <!-- Horizontal line under the window bar -->

                  </div>

                  <div class="single-line" style="top:50px; width:35%; left:14%;"></div>
                  <div class="single-line" style="top:50px; width:20%; left:55%;"></div>
                  <div class="single-line2" style="top:60px; width:40%; left:14%;"></div>
                  <div class="single-line2" style="top:60px; width:25%; left:60%;"></div>
                </div>
              </div>
            </div>



             
            <h2 class="indigo-title pro-display-100" ref="indigoTitle">Migrating Your Infrastructure,
              Databases, and Applications
            </h2>



            <v-carousel :show-arrows="false" :height="mobileScreen ? '400' : '250px'" v-ripple="false"
              hide-delimiter-background>

              <v-carousel-item class="d-flex flex-column justify-center align-center">
                <div style="max-width: 100%;  text-align: center; padding: 50px">
                  <p class="database-bg"> <!-- was pa class="indigo-text" -->
                    <b>Bridging Database Realms:</b> Witness your data traverse with grace via orchestrated transitions
                    between <i>Oracle, MySQL, PostgreSQL, and more</i>.
                    Code metamorphosis between PL/SQL, PL/pgSQL, and T-SQL. Ensuring every byte makes its journey, making
                    possible steadfast data integrity throughout the migration, minimizing downtime and maximizing
                    efficiency.
                  </p>
                </div>

              </v-carousel-item>

              <v-carousel-item class="d-flex flex-column justify-center align-center">
                <div style="max-width: 100%;  text-align: center; padding: 50px">
                  <p>
                    <b>Elevating Oracle Forms to APEX:</b> Update and uplift your business by modernizing from <i>Oracle
                      Forms</i> to the dynamic <i>Oracle APEX</i>, or <i>Oracle Reports</i> to <i>Jasper or Office
                      Print</i>. From legacy apps to future-ready interfaces. 
                  </p>
                </div>
              </v-carousel-item>



              <v-carousel-item class="d-flex flex-column justify-center align-center">
                <div style="max-width: 100%;  text-align: center; padding: 50px">
                  <p> <!-- was: p class="indigo-text" -->
                    <b>Unix Odyssey:</b> Navigate the pathways of migration across UNIX landscapes. Whether it's
                    <i>FreeBSD, Solaris, Illumos, AIX, or Linux</i>, ensuring a smooth and secure shift in diverse
                    environments. Plan your decisions to get the best out of operating systems evolution.
                  </p>
                </div>
              </v-carousel-item>

              <v-carousel-item class="d-flex flex-column justify-center align-center">
                <div style="max-width: 100%;  text-align: center; padding: 50px">
                  <p> <!-- was pa class="indigo-text" -->
                    <b>Boundless Directionality:</b> Experience the liberty of bi-directional migrations. Whether it be a
                    database shift, an application transformation, or an operating system leap, migrations are made
                    possible in all directions, on your terms.
                  </p>
                </div>
              </v-carousel-item>

              <v-carousel-item class="d-flex flex-column justify-center align-center">
                <div style="max-width: 100%;  text-align: center; padding: 50px">
                  <p> <!-- was pa class="indigo-text" -->
                    <b>ITHAS</b> doesn't just migrate data, but experiences, possibilities, and futures - with solutions
                    backed by years of expertise, a deep understanding of technology realms, and a commitment to seeing
                    your transformation flourish.
                  </p>
                </div>
              </v-carousel-item>
            </v-carousel>

          </div>
        </div>

      </div>

      

<div class="new-content-wrapper">
  <v-container fluid>

    <!-- Central Title Row -->
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <h2 class="main-title" style="color: #333;">Oracle Database</h2>
      </v-col>
    </v-row>

    <!-- Rows for Content Columns -->
    <v-row justify="center" align="center">

      <!-- Column for Experience -->
      <v-col cols="12" md="6">
        <h3 class="highlighted-heading text-center" style="color: #627254;">Experience</h3>
        <p class="body-1" style="color: #666; margin: 20px auto;">
          Built on more than 10 years of experience in enterprise environments - financial, banking, health, and public sectors. 
          Solutions and customized systems to enhance efficiency, ensure compliance, and secure sensitive data for critical infrastructure.
        </p>
      </v-col>

      <!-- Column for Design and Implementation -->
      <v-col cols="12" md="6">
        <h3 class="highlighted-heading text-center" style="color: #627254;">Design and Implementation</h3>
        <p class="body-1" style="color: #666; margin: 20px auto;">
          Get the most out of Oracle Database - PL/SQL applications that scale in high-transaction environments,
          automation and aggregation of risk exposure in real-time, ASM, multi-master replication, high-end security enhancement,
          real-time analytics, and more. 
        </p>
      </v-col>

    </v-row>
  </v-container>
</div>





      <div class="flyer-wrapper" :class="{ 'full-height': !showCover }" ref="flyerContainer">
        <div class="flyer-container" :class="{ open: !showCover }" style="margin-top: 50px;">

          <!-- Cover -->
          <v-container v-if="showCover" fluid>
            <v-row justify="center" align="center">
              <v-col class="text-center">
                <!-- Title with a larger font size and bold styling -->
                <h1 class="underline-style">Virtual Platforms and Containers</h1>

                <!-- Description or subheading with a slightly muted color for contrast -->
                <p class="subtitle-1 grey--text text--lighten-1">Experience Virtualization at Its Finest</p>

                <!-- A styled button with a primary color, large size, and elevation for emphasis -->
                <v-btn color="teal lighten-1" large @click="openFlyer" elevation="2"
                  style="font-weight: 700; margin-top: 15px;">Explore Now</v-btn>

              </v-col>
            </v-row>
            <!-- Centered image container -->
            <div class="centered-image-container">
              <!-- Container for the image with custom size -->
              <div class="image-containers">
                <img src="@/assets/containers.png" alt="Containers Image" class="smaller-image" />
              </div>
            </div>
          </v-container>


          <!-- Pages #639CB4-->
          <v-container v-else fluid class="open-flyer-container">
            <v-row no-gutters class="full-height-row flyer-row">
              <v-col v-if="!isMobile" cols="4" class="page page-background-3 pro-display-100" :class="{ folded: !isFlyerOpen }"
                >

                <div @click="closeFlyer" class="back-link">
                  <v-icon small>mdi-chevron-left</v-icon> Back
                </div>
                <div class="flyer-content"
                  :class="{ 'flyer-content-closing': !isFlyerOpen, 'flyer-content-closing-folded': !isFlyerOpen }">
                  
                  <b>ITHAS</b> is discerningly choosing simplicity, performance, and stability over popular hypes. And
                  not just as alternatives but as pioneering solutions which set the stage for OS-level virtualization and
                  isolation. Predating the fleeting tech trends, designed from inception for mission-critical
                  applications, they've been time-tested, setting a benchmark in reliability and innovation - making them
                  an enduring and trusted choice for those who demand excellence.
                  <br><br>
                  <b>Built-In Security:</b> Instead of retrofitting security patches, systems are designed from the ground
                  up with protection in mind, reducing vulnerabilities and ensuring peace of mind.

                </div>
              </v-col>
              <!-- #41663D -->
              <v-col v-if="!isMobile" cols="4" class="page page-background pro-display-100" >
                <div class="flyer-content" :class="{ 'flyer-content-closing': !isFlyerOpen }">
                  <br><br>
                  If you're a software developer aiming to test your application on multiple OS environments, you are
                  provided the flexibility but with a fortified security architecture.
                  <br><br>
                  No challenges with privilege escalation and isolation
                  <br><br>
                  With hardware-assisted virtualization, you can run diverse instances concurrently on a single host with
                  the assurance that they secured from potential breaches.
                  <br><br>
                  Run multiple different OS instances concurrently on a single host, streamlining your testing and
                  integration processes.
                  <br><br>
                </div>
              </v-col>
              <v-col v-if="!isMobile" cols="4" class="page page-background-2 pro-display-100" :class="{ folded: !isFlyerOpen }"
                >
                <div class="flyer-content">
                  <br><br>
                  Custom solutions utilizing power of Solaris Zones/FreeBSD Jails and Bhyve:
                  <br><br>
                  Engineering environments that scale based on your application’s demands.<br><br>
                  Building environments that auto-deploy, manage lifecycles, and ensure high availability.<br><br>
                  Integrating best of open-source tools optimized for your requirements without the additional
                    overhead. 
                </div>
              </v-col>



              <v-col v-if="isMobile" cols="12" class="page page-background-3 pro-display-100" >
                <div @click="closeFlyer" class="back-link">
                  <v-icon small>mdi-chevron-left</v-icon> Back
                </div>
                <div class="flyer-content">
                  
                  <b>ITHAS</b> is discerningly choosing simplicity, performance, and stability over popular hypes. And
                  not just as alternatives but as pioneering solutions which set the stage for OS-level virtualization and
                  isolation. Predating the fleeting tech trends, designed from inception for mission-critical
                  applications, they've been time-tested, setting a benchmark in reliability and innovation - making them
                  an enduring and trusted choice for those who demand excellence.
                  <br><br>
                  <b>Built-In Security:</b> Instead of retrofitting security patches, systems are designed from the ground
                  up with protection in mind, reducing vulnerabilities and ensuring peace of mind.
                </div>
              </v-col>
              <v-col v-if="isMobile" cols="12" class="page page-background pro-display-100">
                <div class="flyer-content">
                  If you're a software developer aiming to test your application on multiple OS environments, you are
                  provided the flexibility but with a fortified security architecture.
                  <br><br>
                  No challenges with privilege escalation and isolation.
                  <br><br>
                  With hardware-assisted virtualization, you can run diverse instances concurrently on a single host with
                  the assurance that they secured from potential breaches.
                  <br><br>
                  Run multiple different OS instances concurrently on a single host, streamlining your testing and
                  integration processes.
                  <br><br>
                </div>
              </v-col>
              <v-col v-if="isMobile" cols="12" class="page page-background-2 pro-display-100" >
                <div class="flyer-content">
                  
                  Custom solutions utilizing power of Solaris Zones/FreeBSD Jails and Bhyve:
                  <br><br>
                  Engineering environments that scale based on your application’s demands.<br><br>
                  Building environments that auto-deploy, manage lifecycles, and ensure high availability.<br><br>
                  Integrating best of open-source tools optimized for your requirements without the additional
                    overhead. 
                </div>
                <div @click="closeFlyer" class="back-link">
                  <v-icon small>mdi-chevron-left</v-icon> Close
                </div>
              </v-col>

            </v-row>
          </v-container>

        </div>
      </div>


      <div class="blank-section with-border">
        <!-- Existing content goes here -->

        <!-- New blank section -->
        <v-container>
          <v-row>
            <v-col cols="12" sm="5">
              <h2 style="margin-bottom: 20px;">Computer Vision</h2>
              <p class="p-background">
                <b>Durer Algorithm</b> is a versatile solution tailored for artists, hobbysts, and designers, offering artistic
                transformation and analysis.
                With <i>Durer Algorithm</i>, you can effortlessly transform real photos into intricate outlines or crosshatch
                versions. <br><br>
                If you are an artist exploring this particular technique used by
                old masters and engravers such as <i>Michelangelo, William Blake and Albrecht Durer</i>, this tool can be
                your invaluable companion. It allows you to convert reference photos into fully detailed crosshatched
                versions or simple outlines, providing you with the perfect reference to envision your work.
                <br><br>
                Whether you're learning the art of crosshatching or simply need a reference image for your masterpiece,
                the precise shading control and level of crosshatch detail will guide your artistic journey.
              </p>
            </v-col>

            <!-- Right Side Swiper -->
            <v-col cols="12" md="7">
              <v-carousel hide-delimiters v-model="carouselValue" cycle interval="6000">
                <v-carousel-item>
                  <img src="@/assets/castle.png" alt="Castle Image" class="image-adjust " />
                </v-carousel-item>
                <v-carousel-item>
                  <img src="@/assets/wall1.png" alt="Wall Image" class="adjusted-image-wall image-adjust rounded-image" />
                </v-carousel-item>
                <v-carousel-item>
                  <img src="@/assets/athena.png" alt="Athena Image" class="adjusted-image-athena image-adjust" />
                </v-carousel-item>

              </v-carousel>

            </v-col>
          </v-row>
        </v-container>
      </div>

    



    </v-main>
  </v-app>

  <!-- Modern Footer -->
  <v-footer class="modern-footer">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="6" class="footer-section">
          <router-link to="/about" class="footer-link" :class="{'footer-title-dark': isDarkMode}">
  <h3 class="footer-title">About</h3>
</router-link>

          <p class="footer-content">UNIX Engineering<br>Storage Management<br>Oracle Database and APEX<br>Software
            Development<br>Embedded Systems</p>
        </v-col>
        <v-col cols="12" md="6" class="footer-section">
          <h3 class="footer-title">Contact</h3>
          <p class="footer-content">
            <b>Email:</b> <a href="mailto:contact@ithas-site.com"
              style="color: inherit; text-decoration: none;">contact@ithas-site.com</a><br />
          </p>
        </v-col>
      </v-row>

      <v-row justify="center" class="social-icons">
        <v-col cols="12">
          <a href="https://www.linkedin.com/company/ithas/" target="_blank" rel="noopener noreferrer"
            style="color: YOUR_DESIRED_COLOR; text-decoration: none;">
            <v-icon size="24" class="social-icon">mdi-linkedin</v-icon>
          </a> <span>&copy; ITHAS 2023</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import 'vuetify/dist/vuetify.css';
import AppBar from '@/AppBar.vue';
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure FontAwesome is imported

//import FullWidthSection from '@/components/FullWidthSection.vue'; 
//import prismicClient from '../prismic.js';
//import Prismic from 'prismic-javascript';
//import { Predicates } from '@prismicio/client';
//import { usePrismic } from '@prismicio/vue';



export default {
  components: {
    //FullWidthSection,  Register the FullWidthSection component
    AppBar,
  },
  data() {
    return {
      bgImage: require('@/assets/nature2.png'),
      particleStyles: [
      { width: '30px', height: '30px', filter: 'blur(1px)', transform: 'translate(5vw, 5vh)', backgroundColor: 'hsl(0, 0%, 80%)' },
  { width: '20px', height: '20px', filter: 'blur(0.3px)', transform: 'translate(20vw, 10vh)', backgroundColor: 'hsl(0, 0%, 30%)' },
  { width: '10px', height: '10px', filter: 'blur(1px)', transform: 'translate(35vw, 15vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '35px', height: '35px', filter: 'blur(0.5px)', transform: 'translate(50vw, 20vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '10px', height: '10px', transform: 'translate(65vw, 25vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '60px', height: '60px', filter: 'blur(1.0px)', transform: 'translate(80vw, 30vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '4px', height: '4px', filter: 'blur(0.3px)', transform: 'translate(10vw, 45vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  // Additional particles for even coverage
  { width: '25px', height: '25px', filter: 'blur(0.4px)', transform: 'translate(25vw, 55vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '15px', height: '15px', filter: 'blur(0.5px)', transform: 'translate(40vw, 65vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '20px', height: '20px', filter: 'blur(0.6px)', transform: 'translate(55vw, 75vh)', backgroundColor: 'hsl(0, 0%, 90%)' },
  { width: '50px', height: '50px', filter: 'blur(0.7px)', transform: 'translate(70vw, 85vh)', backgroundColor: 'hsl(0, 0%, 90%)' },

        // Add more particles as needed
      ],
      /*email Netlify Form*/
      valid: true,
      name: '',
      email: '',
      message: '',
      nameRules: [
        v => !!v || 'Name is required', // Checks for non-empty input
        v => (v && v.length <= 50) || 'Name must be less than 50 characters', // Limits the length of the name
      ],
      emailRules: [
        v => !!v || 'E-mail is required', // Checks for non-empty input
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid', // Uses regex to check for a valid email format
      ],
      messageRules: [
        v => !!v || 'Message is required', // Checks for non-empty input
        v => (v && v.length <= 500) || 'Message must be less than 500 characters', // Limits the length of the message
      ],
      /* end of email */

      carouselValue: 0,

      mobileScreen: window.innerWidth <= 768,
      aboutTitle: null, //prismic 
      unixFeatureContent: '', // Initialize as an empty string, prismic  
      response: null,
      scrollPosition: 0,
      isMobile: false, // Add a flag to check if the screen size is mobile or not
      isDarkMode: false,
      isShrinking: false,
      showFullWidthSection: true,
      show: false,
      showCover: true,
      isFlyerOpen: false, //for flyer
      /*ratingValue: 0,
        customIcons: {
          // Use Font Awesome icons for the rating stars
          iconFull: 'fas fa-star', // fas fa-star is for a filled star
          iconHalf: 'fas fa-star-half-alt', // fas fa-star-half-alt is for a half-filled star
          iconEmpty: 'far fa-star', // far fa-star is for an empty star
        },*/
      cardTitle: "Oracle Database and Application Development",
      cardContent: "Implementation, configuration and administration of Oracle Database. Data-driven application development with Oracle APEX.",
      timelineItems: [
        {
          title: 'Storage Management', // Replace with your custom title
          content: 'Data integrity in HA environments with <b>ZFS</b>. <br>Implemented and administrated ZFS storage pools across data centers.<br>Optimization and performance tuning for <i>Elasticsearch</i><br>',
          color: 'white',
        },
        {
          title: 'System Engineering', // Replace with your custom title
          content: 'Built and supported multiple systems based on <i>FreeBSD</i>, <i>Illumos</i> and <i>Linux</i>.<br>Provided consultation and support for legacy <i>Solaris 10</i> environments<br>Provisioning and advanced containerization with <i>SmartOS</i><br>Native configuration management',
          color: 'white',
        },
        {
          title: 'Oracle Database',
          content: '10 years of proven track record of delivering excellence in public, health, financial and educational sectors.<br>Oracle Database implementation and administration, backup and security<br>Oracle APEX development from version 4.1 to 23.1<br><i>Over 50 projects</i><br><b>Oracle Forms</b> to <b>APEX</b> migration',
          color: 'white',
        },
        {
          title: 'Software and Web Development', // Replace with your custom title
          content: 'Designed and implemented <i>Vue.js</i> solutions, complemented by robust <i>Node.js</i> backends.<br><br>Collaborated to build high-quality platforms and a range of proprietary products.<br><br>Built highly custom JavaScript solutions powered by <i>Vue.js framework</i> in tandem with <i>Oracle APEX</i><br><br>C/C++ development',
          color: 'white',
        },
        // Add more timeline items as needed
      ],

      focusedIndex: 0,
      observer: null, // To hold the Intersection Observer instance

      showMenu: false,
      menuEntries: [
        { id: 'home', label: 'Home' },
        { id: 'portfolio', label: 'Portfolio' },
        { id: 'about', label: 'About' },
        { id: 'contact', label: 'Contact' },
      ],
    };
  },

  mounted() {


   // Add a click event listener to the whole document
   document.addEventListener('click', this.handleClickOutside, true);


    this.$nextTick(() => {
      this.setupObserver();
    });

    //console.log(this.$prismic);

    this.setupObserver();

    this.checkIsMobile(); // Call the method on mount to set the initial isMobile value


    // Add a resize event listener to update the isMobile flag
    window.addEventListener('resize', this.checkIsMobile);

    const title = this.$refs.indigoTitle;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          title.classList.add('indigo-title-slide-in');
        } else {
          title.classList.remove('indigo-title-slide-in');
        }
      });
    });

    observer.observe(title);

  },

  beforeUnmount() {
    // Use beforeUnmount instead of beforeDestroy
    window.removeEventListener('resize', this.checkIsMobile); // Remove the resize event listener on component unmount


    if (this.observer) {
      this.observer.disconnect(); // Stop observing when component is destroyed
    }

    document.removeEventListener('click', this.handleClickOutside, true);
  },





  methods: {
   /* handleToggleMenu(show) {
    this.showMenu = show;
  },*/
  handleToggleMenu(showMenu) {
  this.showMenu = showMenu;
  //console.log('showMenu updated to:', showMenu);
},


    
  handleClickOutside(event) {
    // Check if the click is outside the AppBar element
    if (!this.$el.contains(event.target) && this.showMobileMenu) {
      this.toggleMobileMenu(); // Close the mobile menu
      this.$emit('close-menu'); // Emit an event to notify the parent
    }
  },

    toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
  },


    /*email methods*/
    submit() {
      if (this.$refs.form.validate()) {
        // Here you would typically send the form data to your server
        // For Netlify, the form element handles the submission

      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    }
    ,
    validate() {
      this.valid = this.$refs.form.validate();
    },


    setupObserver() {
      const options = {
        root: null,
        rootMargin: '0px 0px -45% 0px',
        threshold: 0.9,
      };

      this.observer = new IntersectionObserver(this.handleIntersect, options);

      // Observe the timeline titles instead of the icons
      if (this.$refs.timelineTitles && this.$refs.timelineTitles.length) {
        this.$refs.timelineTitles.forEach((title, index) => {
          title.dataset.index = index; // Assign an index to each title
          this.observer.observe(title);
        });
      }
    },
    handleIntersect(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const itemIndex = parseInt(entry.target.dataset.index, 10);
          if (!isNaN(itemIndex)) {
            this.focusedIndex = itemIndex;
          }
        }
      });
    },



    // next asyncs are fetching the content from Prismic docs and types. API ID is used so for aboutTitle type, API ID from prismic is "title"
    // also they need to be called in created(), to call the methods on load. and defined / initialized in data() return for bind variables, like:
    //aboutTitle: null,
    //unixFeatureContent: ''
    //then used in template to show their Prismic content, ie: <h2 class="about-title">{{ aboutTitle }}</h2>

    async getContent() {
      try {
        const docId = 'ZS-88REAACEASxWD'; //Doc ID is found from URL in Prismic when editing document
        const response = await this.$prismic.client.getByID(docId);
        //console.log("Prismic Response:", response);

        if (response && response.data && response.data.title && response.data.title.length > 0) {
          this.aboutTitle = response.data.title[0].text;
        }
      } catch (error) {
        console.error('Error fetching data from Prismic:', error);
      }
    },

    // method to fetch unixFeatureContent from Prismic
    //API ID is used so for aboutTitle type, API ID from prismic is "unixfeaturecontent" lowercase
    async fetchUnixFeatureContent() {
      try {
        const docId = 'ZTvSvhEAACUAYirp';
        const response = await this.$prismic.client.getByID(docId);

        if (response && response.data && response.data.unixfeaturecontent && response.data.unixfeaturecontent.length > 0) {
          this.unixFeatureContent = response.data.unixfeaturecontent[0].text;
        }
      } catch (error) {
        console.error('Error fetching data from Prismic:', error);
      }
    },


    // Function to scroll to the top of the page
    scrollToTop() {
      window.scrollTo({
        top: 64,
        behavior: 'smooth',
      });
    }, checkIsMobile() {
      // Method to check if the screen size is mobile or not
      this.isMobile = window.innerWidth <= 767.98;
    },
    toggleMenu() {
      // Only toggle the showMenu state if the screen size is mobile
      //if (this.isMobile) {
        this.showMenu = !this.showMenu;
        //console.log('Menu toggled:', this.showMenu);
      //}
    },
    closeMenu() {
      this.showMenu = false;
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    navigate(event, link) {
      // Prevent the default link behavior if the event is provided
      if (event) event.preventDefault();

      if (link === 'contact') {
        window.location.href = 'mailto:contact@ithas-site.com';
      } else if (link === 'home' || link === 'portfolio') {
        this.scrollToSection(link);
      } else {
        this.scrollToSection('about');
      }

      // Close the mobile menu after clicking
      this.showMenu = false;
    },
    toggleExpand(index) {
      if (this.show === index) {
        this.show = null; // Collapse if already expanded
      } else {
        this.show = index; // Expand the clicked card
      }
    },

    openFlyer() {
      this.showCover = false;

      this.$nextTick(() => {
        this.$refs.flyerContainer.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        setTimeout(() => {
          this.isFlyerOpen = true;
        }, 100);
      });
    },
    /* closeFlyer() {
       this.isFlyerOpen = false;
       setTimeout(() => {
         this.showCover = true;
       }, 500);
     },*/
    closeFlyer() {
      this.isFlyerOpen = false;
      setTimeout(() => {
        this.showCover = true;
      }, 500);  // adjust duration based on when you want the cover to appear in relation to the content fade/fly animation.
    },

  },
  created() {
    
    this.getContent();
    this.fetchUnixFeatureContent();
  },

  // ... other code ...
};

</script>

<style scoped>
@import url('https://fonts.cdnfonts.com/css/terminus');
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

/* define font face for San Francisco Pro Display, which is font stored in assets. Another one is called SF Pro Display and referenced from CDN*/ 
@font-face {
  font-family: 'San Francisco Pro Display';
  src: url('~@/assets/fonts/SF-Pro-Display-Ultralight.otf') format('opentype');
  font-weight: 100; /* Ultralight */
  font-style: normal;
}


.dark-mode {
  background-color: #344955; /* Dark background */
  color: white; /* Light text */
}

.hero-container {
  
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url('@/assets/hero-bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden; /* Ensures the pseudo-element does not extend outside the container */
  /*border-bottom: 2px solid black;*/
}

.hero-container.dark-mode-bg {
  background-color: #35374B; /* Change background to black in dark mode */
  background-image: none; /* Optionally remove the image in dark mode */
}

.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.97) 50%, rgba(255, 255, 255, 1) 100%);
  z-index: 0;
}

/* Ensures this layer is behind the content but above the existing ::before layer */
/*
.hero-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom left, transparent 70%, grey 20%); 

  z-index: 0; 
}
*/


.hero-container.dark-mode-bg::before {
  background: linear-gradient(to right, rgba(53, 55, 75, 0.97) 50%, rgba(53, 55, 75, 1) 100%);

}

.hero-container>* {
  position: relative;
  z-index: 1;
}

.paragraph-text-left {
  text-align: left;
  font-weight: bold;
  color: #35374B;
}

.gradient-text-artofcode {
  background: -webkit-linear-gradient(left, black, #26A69A);
  /* for Chrome, Safari */
  background: linear-gradient(to right, black, #26A69A);
  /* Standard syntax */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Make the text color transparent */
  font-size: 32px !important;
  /* Set the font size */
  font-weight: 900;
  /* Set the font weight */
  font-family: 'SF Pro Display', sans-serif;
}

.gradient-text-artofcode-dark {
  background: linear-gradient(to right, white, #26A69A); /* White to #26A69A for dark mode */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 32px !important;
  font-weight: 900;
  font-family: 'SF Pro Display', sans-serif;
}

.gradient-text-hardware {
  background: linear-gradient(to right, white, grey);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline; /* Ensure the gradient spans only the text */
}

.gradient-text-hardware-title {
  background: linear-gradient(to right, grey, #a0bcc8);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline; /* Ensure the gradient spans only the text */
}




.hero-row {
  text-align: center;
}

@media (max-width: 960px) {

  /* Adjust breakpoint as needed */
  .hero-container {
    margin-top: 100px;
    /* Adds space above the hero section on smaller screens */

  }

  .hero-row .v-col.text-left {
    text-align: center !important;
  }

  .hero-row img {
    display: block;
    /* Makes the image a block element */
    margin: 0 auto;
    /* Centers the image horizontally */
    width: 50%;
    /* Adjust width as necessary, or use max-width to prevent scaling */
  }
}

.display-1 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.subheading {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.v-btn {
  padding: .5rem 2.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 60%;
  /* Set the desired height relative to the parent container */
  width: auto;
  padding-left: 35px !important;
  /* Move the logo 50px to the right */

}

.dark-mode-logo {
  filter: brightness(0) invert(1);
}

/* Adjust the size of the v-toolbar-title */
.v-toolbar-title {
  font-size: 0;
  /* Set font size to 0 to remove any extra space */
}

/* Adjust the size of the logo within the v-toolbar-title */
.v-toolbar-title img.logo-image {
  height: 40px;
  /* Set the desired height of the logo */
  width: auto;
  /* Automatically adjust the width based on the height */
}

.partners-section {
  padding: 20px 0;
  /* Adjust padding as necessary */
}

.partner-image {
  object-fit: contain;
  width: 100%;
  /* or any specific width */
  height: auto;
  /* maintain aspect ratio */
  margin: 0 50px;
  filter: brightness(0) invert(1);
  /* to use CSS trick to make all different colored logos appear all white */
}

.smaller-logo {
  width: 70%;
  /* Adjust the width as needed */
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.partners-heading {
  user-select: none; 
  color: white;
  animation: 1s ease-out 0s 1 slideInFromRight;
  font-weight: 500;
  font-family: 'SF Pro Display', sans-serif;
}

.partners-section .v-col .partner-image {

  margin: 0 50px;
  /* Horizontal margin for spacing */
}

.dark-bg {
  background-color: #4f4f4f;
  /* Dark grey background */
}

.color-section {
  background-color: #ebe6db;
  /* Set the desired color */
  width: 100%;
  height: 50vh;
  /* Set the desired height, adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  text-align: center;
  flex: 1;
}

.section-title {
  font-family: 'Roboto', sans-serif;
  /* Use the desired font name */
  font-size: 28px;
  margin-bottom: 10px;
}

.section-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.menu-entries {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-entry {
  font-size: 16px;
  font-weight: bold;
  margin-left: 16px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: black;
  /* Set the font color to #14566B */
}

.menu-entry-dark {
  color: white; /* Color for dark mode */
}

.menu-entry::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background: none;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.menu-entry:hover::before {
  visibility: visible;
  transform: scaleX(1);
  background: linear-gradient(to right, gold, goldenrod);
}

/* Container for Features */
.feature-container {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  /* Add this line to make positioning easier */
}

/* no transparency and overlay*/ 
/*.first-container {
  width: 100%; 
  height: 50vh; 
  background-image: url('~@/assets/particles.png'); 
  background-repeat: repeat; 
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
}*/

/* transparency and overlay*/
.first-container {
  width: 100%;
  background-image: url('~@/assets/particles.png');
  background-repeat: repeat; /* Repeat the image to cover the whole area */
  background-size: cover; /* Cover the entire container */
  background-position: center top; /* Center the background image at the top */
  display: flex;
  justify-content: center;
  position: relative;
}

@media (min-width: 768px) { /* Adjust this breakpoint as needed */
  .first-container {
    padding-top: 5%; 
    padding-bottom: 2%; /* Adjust padding to ensure proper spacing */
  }

  .first-container::before {
    /* Gradient overlay that fades from transparent at the top to opaque towards the bottom */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
}

@media (max-width: 767px) {
  .first-container {
    padding-bottom: 0; /* Adjust padding for smaller screens */
  }

  .first-container::before {
    /* Remove the gradient effect on smaller screens, or adjust as necessary */
    background: none;
  }
}

.first-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; /* Ensure the overlay is below the content to not interfere with it */
}

/* Ensure content is placed above the overlay */
.first-container > * {
  position: relative;
  z-index: 1;
}



.content-inner {
  width: 80%; /* Less than the full width, adjust as needed */
  padding: 0px 0; /* Top and bottom padding, no horizontal padding */
  
}

.section-div {
  border: 1px solid #35374B;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #4f4f4f; /* Light grey background */
  color: white;
  border-radius: 15px;
}

.opacity-75 {
  opacity: 0.75;
}


/* Ensure the content within v-container overlays the SVG */
.v-container > .v-row {
  position: relative;
  z-index: 1;
}

::v-deep .theme--light.v-timeline:before {
    background-color: red !important;
}


.action-bar-icon {
  width: 32px;
  /* Adjust the width as needed */
  height: 32px;
  /* Adjust the height as needed */

  opacity: 0.5;
  /* Set the initial opacity to control the sides' visibility */

}

.container-inner
{
  font-weight: 500;
  font-family: 'SF Pro Display', sans-serif;
}

.inner-hardware{
  font-weight: 100;
  font-family: 'SF Pro Display', sans-serif;
}

.pro-display-100{
  font-weight: 100;
  font-family: 'SF Pro Display', sans-serif;
}

.pro-display-800{
  font-weight: 800;
  font-family: 'SF Pro Display', sans-serif;
}

.new-container-first {
  position: relative;
  z-index: 1;
  overflow: hidden; /* Ensure the pseudo-element doesn't extend outside */
}

.new-container-first::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url('@/assets/nature-up.png'); /* Adjust path as necessary */
  background-position: left-top;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.1; /* This sets the base opacity of the image */
}

.new-container-first::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 100%);
  z-index: 1; /* Above the image */
  opacity: 0.5; /* Control the overall effect's opacity */
}

/* Mobile devices */
@media (max-width: 768px) {
  .new-container-first::before {
    background-size: cover; /* Use cover for mobile */
  }
}


.new-container-second {
  position: relative;
  z-index: 1;
  overflow: hidden; /* Ensure the pseudo-element doesn't extend outside */
}

.new-container-second::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url('@/assets/nature2.png'); /* Adjust path as necessary */
  background-position: left-top;
  background-repeat: none;
  background-size: 100% 100%;
  opacity: 0.1; /* This sets the base opacity of the image */
}

.new-container-second::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 100%);
  z-index: 1; /* Above the image */
  opacity: 0.2; /* Control the overall effect's opacity */
}

/* Mobile devices */
@media (max-width: 768px) {
  .new-container-second::before {
    background-size: cover; /* Use cover for mobile */
  }
}



/* Terminal Icon */
.feature-icon.terminal-icon {
  /* Add styles to match the other features */
  width: 60px;
  /* Adjust the size of the icon as needed */
  height: 40px;
  /* Adjust the size of the icon as needed */
  background-color: black;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: monospace;
  font-size: 16px;
  /* Adjust the font size of the icon text */
  position: relative;
  /* Change the position to relative */
  margin: 0 auto 20px;
  /* Center the icon horizontally and add margin to push the card content down */
}

/* Prevent text selection */
.feature-icon.terminal-icon span {
  user-select: none;
}

/* SQL Icon */
.feature-icon.sql-icon {
  /* Add styles to match the other features */
  width: 60px;
  /* Adjust the size of the icon as needed */
  height: 40px;
  /* Adjust the size of the icon as needed */
  background-color: black;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: monospace;
  font-size: 16px;
  /* Adjust the font size of the icon text */
  position: relative;
  /* Change the position to relative */
  margin: 0 auto 20px;
  /* Center the icon horizontally and add margin to push the card content down */
}

/* Prevent text selection */
.feature-icon.sql-icon span {
  user-select: none;
}


/* Feature Title */
.feature-title {
  color: black;
  /*#14566B;  Set the title color to #14566B */
  font-size: 24px;
  /* You can adjust the font size as needed */
  margin-bottom: 10px;
}

/* Feature Content */
.feature-content {
  color: black;
  /*#2F879F;  Set the content text color to #2F879F */
  font-size: 16px;
  /* You can adjust the font size as needed */
}

/* </> Icon Styles */
.feature-icon.team-icon {
  width: 60px;
  /* Adjust the size of the icon as needed */
  height: 40px;
  /* Adjust the size of the icon as needed */
  background-color: black;
  /* Set the background color to the desired color */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  /* Set the icon text color to white */
  font-family: monospace;
  font-size: 16px;
  /* Adjust the font size of the icon text */
  position: relative;
  /* Change the position to relative */
  margin: 0 auto 20px;
  /* Center the icon horizontally and add margin to push the card content down */
}

/* Prevent text selection */
.feature-icon.team-icon span {
  user-select: none;
}

.feature-icon.team-icon i.gear-icon {
  font-size: 12px;
  /* Adjust the size of the gear icon */
}


.feature-icon.code-icon {
  width: 60px; /* Adjust the size of the icon as needed */
  height: 40px; /* Adjust the size of the icon as needed */
  background-color: #78A083; /* Set the background color to the desired color */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff; /* Set the icon text color to white */
  font-family: monospace;
  font-size: 16px; /* Adjust the font size of the icon text */
  position: relative; /* Change the position to relative */
  margin: 0 auto 20px; /* Center the icon horizontally and add margin to push the card content down */
}

/* Prevent text selection */
.feature-icon.team-code span {
  user-select: none;
}

.feature-icon.team-icon i.gear-icon {
   font-size: 12px; /* Adjust the size of the gear icon */
 }

/* General Styles */
/*body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  font-family: 'Quicksand', sans-serif!important;
  margin: 0;
  padding: 0;

  position: relative;
  background: linear-gradient(135deg, #e6c295, #f9e1b6);
  overflow: hidden;


}*/

/* Glassy Effect for Features Section */


.about-feature {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  /* Transparent white background for features */
  text-align: center;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* About Section Styles */
.about-container {
  text-align: center;
  /*padding: 100px 0;
    
    background: linear-gradient(to right, white, #E0DFD5) !important;
    backdrop-filter: blur(10px); 
    border-radius: 8px;*/
  margin-bottom: 30px;

}

/* About Section Styles */
.contact-container {
  text-align: center;
  padding: 100px 0;
  background-color: #f2f2f2;
  /* Set the background color to match the toolbar */
  backdrop-filter: blur(10px);
  /* Add the blur effect */
  border-radius: 8px;
  margin: 30px;
}

/* Change the text color to #2F879F */
.about-container p.about-description,
.about-container .about-feature p {
  color: black;
  /*#2F879F;*/
}

.about-container .about-feature h3,
.about-container h2.about-title {
  color: black;
  /*#11456;*/
}

.about-title {
  font-family: 'Quicksand', sans-serif!important;
  font-size: 24px;
  /* Set the desired font size (adjust as needed) */
  margin-bottom: 20px;
  /* Optional: Add some spacing below the title */
}

.about-description {
  font-size: 30px;
  font-weight: 800;

  font-family: 'Quicksand', sans-serif;
  max-width: 600px;
  margin: 0 auto 40px;
  opacity: 0.9;
}

/* Hide the hamburger icon on desktop (wider screens) */
.v-btn.icon.menu-btn.mobile {
  display: none;
}


.software-process {
  padding-top: 10px; /* Only add top padding */
  display: flex;
  justify-content: center;
}

.software-process-intro {
  font-size: 20px !important;
  /* Set the font size */
  font-weight: 900;
  /* Set the font weight */
  font-family: 'SF Pro Display', sans-serif;
}

.software-process-intro-text {
  font-size: 20px !important;
  /* Set the font size */
  font-weight: 500;
  /* Set the font weight */
  font-family: 'SF Pro Display', sans-serif;
}

.software-process-title {
  font-size: 25px !important;
  /* Set the font size */
  font-weight: 900;
  /* Set the font weight */
  font-family: 'SF Pro Display', sans-serif;
}

.rounded-div {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  max-width: 600px; /* Adjust the width as needed */
}

@media (max-width: 768px) {
  .rounded-div {
    margin: 20px; /* Adds some space around the div on smaller screens */
  }
}


.vi-editor-card {
  background-color: #35374B !important;
  color: white!important;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 8px;
  overflow: hidden;
}

.mac-window-bar {
  background-color: #35374B;
  padding: 8px;
  display: flex;
  align-items: center;
}

.mac-circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.red {
  background-color: #ff605c;
}

.yellow {
  background-color: #ffbd44;
}

.green {
  background-color: #00ca4e;
}

.vi-editor {

  padding: 16px;
  
  white-space: pre-wrap; /* This will preserve the formatting but allow wrapping */
  word-wrap: break-word; /* Breaks long words to prevent overflow */
  overflow-wrap: break-word; /* Standardized property for word-wrap */

}



.new-content-wrapper {
  position: relative;
  padding: 50px 30px 30px;
  border-bottom: 5px solid #EEEEEE;
}

.new-content-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/nature-up.png'); /* Webpack will resolve this */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.1; /* Adjust opacity as needed for transparency */
  z-index: -1; /* Keep the background behind the content */
}

.timeline-container {
  max-width: 800px;
  /* Set the desired maximum width */
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  /* Center the container horizontally */
  background-color: black;
  color: white;
  /* Set the background color to white */
  border-radius: 8px;
  /* Add border-radius for rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* Add box shadow for the outline */
  padding: 20px;
  /* Add padding to the container */
  overflow: hidden;
  /* Clip any overflow content */

}

.sql-icon {
  font-size: 12px;
  /* Adjust the font size as needed */
}

.web-icon {
  font-size: 12px;
  /* Adjust the font size as needed */
}

/* not used currently - for underlining timeline titles when in focus
.underline {
  position: relative;
}

.underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 15%;  
  width: 30%;
  height: 1px;  
  background-color: grey;
}
*/


.v-timeline-item div {
  transition: opacity 0.3s;
}


.timeline-title-line {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  /* Adjust the spacing as needed */

  background: linear-gradient(to left, rgba(47, 135, 159, 0), #2F879F);
  background-size: 50% 1px, 50% 1px;
  background-position: left, right;
  background-repeat: no-repeat;
}

.timeline-title-line.left-gradient {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  background: linear-gradient(to right, rgba(47, 135, 159, 0), #2F879F);
  background-size: 50% 1px, 50% 1px;
  background-position: left, right;
  background-repeat: no-repeat;
}

.timeline-scroll {
  overflow-x: auto;
}

.timeline-mobile-padding {
  padding-bottom: 20px;
}

.mobile-spacer {
  margin-bottom: 40px;
}

.card-section {
  /*margin-top: 40px;*/
  /* Adjust the margin as needed */
  padding: 60px 0;
  background-color: #f9f9f9;
}

.custom-app-bar {
  /*background-color: #f2f2f2!important;  Use a slightly darker shade of grey, adjust as needed */
  font-family: 'San Francisco Pro Display', sans-serif !important;
  font-weight: 100 !important;
    background: linear-gradient(to right, white, #f0f0f0) !important;

}

.dark-app-bar {
  font-family: 'Quicksand', sans-serif;
  background-color: #082032 !important; /* Gradient from white to black for dark mode */
  color: white!important;
}

/* Center the Sections Container */
.sections-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0; /* Remove bottom margin */
  margin-top: 0; /* Ensure no top margin */
}

/* Scroll to Top Button Styles */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  /* Center the button horizontally */
  transform: translateX(-50%);
  /* Adjust the horizontal centering */
  background-color: rgba(0, 0, 0, 0.3);
  /* Set the background color to a less noticeable one */
  color: white;
  /* Set the font color to white */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0.7;
  /* Reduce the opacity to make it less noticeable */
}

.scroll-to-top:hover {
  opacity: 1;
  /* Restore opacity on hover */
}


/* Adjusted height for the larger menu */
.full-height.large {

  position: fixed;
  /* Set the menu to fixed position */
  top: 0;
  /* Fix the menu at the top of the viewport */
  left: 0;
  /* Align the menu to the left edge */
  z-index: 1000;
}

.blur-out {
  filter: blur(5px);
  /* Adjust the blur intensity as needed */
  pointer-events: none;
  /* Prevent interaction with the blurred content */
  transition: filter 0.3s ease-in-out;
  /* Add a smooth transition */
}

/* Full Width Indigo Section */
.full-width-indigo-section {
  width: 100%;
  /*height: 100vh;  Full height of viewport */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}






/*with arrow white pointing down into the indigo*/
.indigo-background {
  /*background-color: #50727B;*/
  background: linear-gradient(to right, #50727B 0%, #344955 100%);
  width: 98%;
  /* 3x larger width */
  /*height: 70vh;  3x larger height */

  padding-top: 40px;
  /* Adjust padding to make space for the triangle */
  padding-bottom: 20px;
  border-radius: 8px;

  box-sizing: border-box;
  text-align: center;
  color: white;
  /* Set text color */
  position: relative;
  /* Add this line to create a positioning context for the pseudo-element */

}


.indigo-background::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  /*border-top: 20px solid white; This time, we use border-top to create the downward triangle and make it white */
  position: absolute;
  top: 0;
  /* Position it right at the top edge */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  /* Ensures the triangle is above other content */
}

/* indigo arrow pointing up into the white */
/*
.indigo-background {
  background-color: #14566B;
  width: 98%;
  padding: 20px; 
  border-radius: 8px;
  box-sizing: border-box;
  text-align: center;
  color: white; 
  position: relative; 
}

.indigo-background::before {
  content: ''; 
  width: 0;
  height: 0;
  border-left: 20px solid transparent; 
  border-right: 20px solid transparent; 
  border-bottom: 20px solid #14566B; 
  position: absolute;  
  top: -20px; 
  left: 50%; 
  transform: translateX(-50%); 
}

*/



.indigo-content {
  max-width: 800px;
  /* Adjust max-width as needed */
  margin: 0 auto;
}


/* these are not proper carousel arrow classes but since z-index of centered-content::before (which is for background image)  
is 1, for arrows to be visible above it they should have higher z-index. 
If centered-content::before would have negative number then it would be invisible (pushed behind everything). it should have  
z-index though so that opacity of background doesnt apply to centered-content text, only to image */
.v-window__next,
.v-window__prev {
  z-index: 3;
  /* Adjust as needed. Just make sure it's higher than the z-index of your content. */
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  /* To make sure the ::before element is relative to this container */
}

.centered-content::before {
  content: "";
  background-image: url('~@/assets/database.png'), url('~@/assets/server.png');
  background-position: left top, right;
  /* Adjust the positions as needed */
  background-repeat: no-repeat, no-repeat;

  background-size: 12%, 8%;
  opacity: 0.04;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  /* To ensure the image stays behind the content */
}

@media (max-width: 768px) {
  .centered-content::before {
    background-size: 150px 120px;
    /* You can adjust this value to your desired fixed size for mobile */
  }
}

.full-width-indigo-section .v-btn .v-btn__content .v-icon {
  color: rgba(255, 255, 255, 0.3);
  /* Opaque white color with 70% opacity */

}




@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.indigo-title-slide-in {
  animation: slideFromLeft 1s forwards;
}



.monitors-and-dots {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}



.horizontal-dots {
  margin: 0 10px;
  /* Adjust spacing between dots */
  font-size: 24px;
}

.indigo-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.indigo-text {
  font-size: 16px;
}

.monitor-outline {
  border: 1.5px solid white;
  border-radius: 10px;
}

.horizontal-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.dot {
  width: 1px;
  height: 1px;
  background-color: white;
  border-radius: 50%;
  margin: 0 5px;
  opacity: 0.5;
  animation: dotAnimation 1s infinite;
}

@keyframes dotAnimation {

  0%,
  100% {
    opacity: 0.5;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.5);
    background-color: white;
  }
}



.code-line {
  height: 1px;
  background-color: white;

  margin: 5px 3px;
  /* Adjust spacing between lines */
}





/* Modern Footer Styles */
.modern-footer {
  background-color: #2f879f !important;
  color: white !important;
  padding: 40px 0;
  text-align: center;
  flex-shrink: 0;
}

.footer-section {
  margin-bottom: 20px;
}

.footer-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.footer-content {
  font-size: 16px;
  opacity: 0.8;
}

.social-icons {
  margin-top: 20px;
  text-decoration: none;
  /* Remove underlining */

}

.social-icon {
  color: #ffffff;
  /* Set the icon color */
  text-decoration: none !important;
  /* Remove underlining */
  margin: 0 10px;
  transition: color 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .footer-section {
    margin-bottom: 10px;
    text-align: center;
    /* centers the content on smaller screens */
  }

  .footer-title,
  .footer-content {
    margin-left: 10px;
    margin-right: 10px;
  }
}




/* Black Section Styles */
.black-section {
  background-color: black;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Circles Container */
.circles-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Circle Styles */
.circle {
  width: 140px;
  height: 140px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  /* Add margin between the circles */
  position: relative;
}

/* Circle Content Styles */
.circle-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Circle Text Styles */
.circle-text {
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-align: center;
}

/* Diagonal Line Styles */
.circle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: rotate(45deg);
  bottom: -1px;
  left: 0;
}





.monitor-container {
  display: flex;
  justify-content: space-between;
}

.monitor {
  position: relative;
  width: 400px;
  height: 300px;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
}

.monitor:first-child {
  opacity: 0.9;
  /* Adjust the opacity value for the first monitor */
}

.monitor:nth-child(2) {
  background-color: #333;
}

.window-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Adjust this to your needs */
}

.window-buttons {
  display: flex;
}

.window-button {
  width: 12px;
  height: 12px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: white;
  /* Adding background color for dots */
}

.toolbar-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ccc;
  margin-left: 5px;
}

.toolbar-dot:nth-child(1) {
  background-color: orange;
}

.toolbar-dot:nth-child(2) {
  background-color: yellow;
}

.toolbar-dot:nth-child(3) {
  background-color: green;
}

/* Your existing styles... */

/* Your existing styles... */

.window-lines {
  position: absolute;
  /*top: 20px;  Adjust the top margin as needed */
  left: 5%;
  /*width: 50%;*/
  height: 4px;
  /* Adjust the line height as needed */
  display: flex;
  align-items: center;
}

.line-start {
  color: white;
  font-size: 10px;
  /* Adjust the font size as needed */
  margin-right: 5px;
  /* Adjust the margin as needed */
}

.line {
  flex-grow: 1;
  height: 50%;
  width: 10% !important;
  /* Adjust the line length as needed */
  background-color: rgba(255, 255, 255, 0.5);
  /* Adjust the opacity as needed */
  border-radius: 2px;
}

.line-start,
.line {
  pointer-events: none;
}

.single-line {
  position: absolute;
  top: 60px;
  /*Adjust the top margin as needed */
  left: 10%;
  width: 50%;
  height: 2px;
  /* Adjust the line height as needed */
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.4);
  /* Adjust the opacity as needed */
  border-radius: 2px;
}

.single-line2 {
  position: absolute;
  top: 60px;
  /*Adjust the top margin as needed */
  left: 10%;
  width: 50%;
  height: 2px;
  /* Adjust the line height as needed */
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.2);
  /* Adjust the opacity as needed */
  border-radius: 2px;
}

.database-icon {
  width: 200px;
  height: 200px;
  background-color: #325e7c;
  border-radius: 50%;
  position: relative;
}

.layer {
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.layer-1 {
  background-color: #1c416f;
  z-index: 3;
}

.layer-2 {
  background-color: #204a86;
  z-index: 2;
}

.layer-3 {
  background-color: #23538f;
  z-index: 1;
}


/*flyer*/


/*.flyer-content-closing {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;  
  transform: translateY(50px);  
  opacity: 0;
}*/

.flyer-container {
  perspective: 1500px;
}

.folded:first-child {
  transform: rotateY(90deg);
  /* Rotate towards the right border (page 2) */
  -webkit-transform: rotateY(90deg);
  /* Add -webkit-transform for Chrome */
  transition: transform 0.5s ease-out;
  -webkit-transition: -webkit-transform 0.5s ease-out;
  /* Add -webkit-transition for Chrome */
  box-shadow: -10px 0 15px rgba(0, 0, 0, 0.5);
}



.flyer-content-closing-folded {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out, box-shadow 0.5s ease-out;
  transform: translateY(30px);
  opacity: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.flyer-content-closing {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  transform: translateY(50px);
  opacity: 0;
}



.centered-image-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  /* Add top margin for spacing */
}

/* Add this CSS to your styles */
.image-containers {
  max-width: 100px;
  /* Adjust the maximum width as needed */

  margin-top: 15px;
  /* Adjust the margin as needed */
}

.smaller-image {
  max-width: 100%;
  /* Adjust the maximum width as needed */
  height: auto;
  /* Maintain aspect ratio */
}


.flyer-container {
  width: 80%;
  margin: 0 auto;
  min-height: 300px;
  /* Allows container to grow based on content */
  display: flex;
  justify-content: center;
  align-items: center;
  /* This will vertically center the cover */
  flex-direction: column;
  /* Stacks child elements vertically */
  padding-bottom: 40px;
  /* Provides spacing at the bottom */

}

/* Remove the fill-height class from <v-row> in the Cover section. It forces 100% height, which we don't want when using the flex approach */


.flyer-content {
  padding: 20px;
  /* Adjust this value as needed */

}

/*
.folded {
  transform: scaleX(0);
  transition: transform 1s;

      transform: rotateY(90deg);

}

.flyer-cover {
    width: 100%;
    height: 100%;
    background: #ddd;
    text-align: center;
    line-height: 300px;
    position: absolute;
    z-index: 5; 

}

*/

.folded {
  transform: scaleX(0);
  transition: transform 1s;
}



.page-2 .close-flyer-btn {
  display: none;
  /* Initially hidden */
}




/*

.page {
  transform-style: preserve-3d;
    transition: transform 1s ease-out;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    backface-visibility: hidden; 


}


.folded:first-child {
    transform: rotateY(-90deg);
}

.folded:last-child {
    transform: rotateY(90deg);
}

.open-flyer-container {
    perspective: 1200px;
}
*/
.page-background {
  position: relative; /* This enables the absolute positioning of the pseudo-element relative to this container. */
  background-color: #F2EFE5; /* Original background color */
  color: black;
  box-shadow: 
    0 -10px 15px -10px rgba(0, 0, 0, 0.5), /* Top shadow */
    0 10px 15px -10px rgba(0, 0, 0, 0.5); /* Bottom shadow */

}

.page-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('@/assets/flyer.png'); /* Path to your image */
  background-size: cover;
  background-position: center;
  opacity: 0.2; /* 80% transparency */
  z-index: 0; /* Ensures the content on top is clickable and interactable */
}

.page-background-2 {
  position: relative; /* This enables the absolute positioning of the pseudo-element relative to this container. */
  background-color: #A3B4A2; /* Original background color */
  color: black;
  box-shadow: 
    0 -10px 15px -10px rgba(0, 0, 0, 0.5), /* Top shadow */
    0 10px 15px -10px rgba(0, 0, 0, 0.5), /* Bottom shadow */
    10px 0 15px -10px rgba(0, 0, 0, 0.5); /* Right shadow */

}

.page-background-2::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('@/assets/flyer3.png'); /* Path to your image */
  background-size: cover;
  background-position: center;
  opacity: 0.2; /* 80% transparency */
  z-index: 0; /* Ensures the content on top is clickable and interactable */
}


.page-background-3 {
  position: relative; /* This enables the absolute positioning of the pseudo-element relative to this container. */
  background-color: #38686A; /* Original background color */
  color: white;
  box-shadow: 
    -10px 0 20px -10px rgba(0, 0, 0, 0.5), /* Left shadow */
     0 -10px 20px -10px rgba(0, 0, 0, 0.5), /* Top shadow */
     0 10px 20px -10px rgba(0, 0, 0, 0.5); /* Bottom shadow */

}

.page-background-3::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('@/assets/flyer3.png'); /* Path to your image */
  background-size: cover;
  background-position: center;
  opacity: 0.1; /* 80% transparency */
  z-index: 0; /* Ensures the content on top is clickable and interactable */
}


.back-link {
  position: relative;
  z-index: 9999; /* Significantly high to ensure it's on top */
  cursor: pointer;
  color: white;
  padding: 10px 0;
  font-weight: bold;
}

/* Target the middle column that represents pages near the binding */
.page:nth-child(2)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; /* Ensures the overlay doesn't interfere with clicks */
  z-index: 999; /* Adjust as necessary to ensure it appears over the content but under any interactive elements like your back-link */
  
  /* Create a gradient that simulates the shadow of pages curving into the binding */
  background-image: linear-gradient(to right, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
}

/* Adjust the first and last page to have a matching but inverse effect for consistency */
.page:first-child::before, .page:last-child::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 999;
  
  /* Inverse gradient for the effect */
  background-image: linear-gradient(to left, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
}


/*
.flyer-wrapper.full-height {
  height: calc(100vh - 56px); 
  width: 100%;
}

.flyer-container.open {
  height: 100%;
  width: 100%;
}

.open-flyer-container {
  height: 100%;
}

.full-height-row {
  height: 100%;
}

.page {
  height: 100%; 
} */



.no-select {
  user-select: none !important;
  /* Standard syntax */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10+ */
  pointer-events: none;
}



.full-size-flyer .page {
  height: 100vh;
  /* This makes each page as tall as the viewport */
  /* Other styles as required */
}

.flyer-row>.v-col {
  width: 33.33%;
  /* When the flyer is open, each page (v-col) will take up a third of the width */
  min-height: 100%;
  /* This will ensure each column fills the height of the row */
  transition: width 0.3s ease;
  /* Transition effect for smooth unfolding */
}

/* Styles for the flyer pages on mobile */
@media (max-width: 767px) {
  .full-height-row {
    height: auto;
    /* or min-height: 100%; to ensure it takes up at least the full height */
    overflow: visible;
    /* Ensure that overflowing content is visible */
  }
}


.footer-link {
  text-decoration: none;
  color: inherit;
  /* assuming you want to keep the original color */
}

.footer-link:hover {
  cursor: pointer;
}

.blank-section {
  margin-top: 20px;
  margin-bottom: 20px;


}

.p-background {
  position: relative;
  padding: 20px;
  /* Add some padding for spacing */
}

.p-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/dippen.jpg");
  /* Replace with the correct path to your image */
  background-size: contain;
  /* Adjust as needed */
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  /* Adjust the opacity as needed */
  z-index: -1;
  border-radius: 5px;
  /* Optional: Add rounded corners */
  background-size: 60%;
}


.underline-style:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 0;
  left: 25%;
  border-bottom: 1px solid #04724D;
}



.blank-section.with-border {
  border-top: 1px solid transparent;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-repeat: no-repeat;
  background-size: 100% 1px;
  background-position: center top;
}


/*
 .image-container-cent {
    text-align: center; 
     
  }

  .image-container img {
    max-width: 50%; 
    height: auto; 
    display: block;
    margin: 0 auto; 
  
  }
  */

.adjusted-image {
  position: relative;
  top: 120px;
  /* Adjust this value to move the image vertically down */
  max-width: 50%;
  /* Adjust as needed */
  height: auto;
}

.adjusted-image-wall {
  top: 100px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 60%;
  height: auto;
}


.image-container {
  text-align: center;
  padding: 20px;
}

.adjusted-image-athena {
  top: 100px;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 40%;
  height: auto;
}


.image-adjust {
  width: 100% !important;
  /* Adjust as needed */
  height: auto !important;
}

.rounded-image {
  border-radius: 20px;
  /* Adjust the radius to your preference */
  /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
  opacity: 0.8;
  /* Adjust opacity as needed */

}








.centered-carousel .v-carousel-item {
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  /* Vertical centering */
}


/* Make the carousel arrows and controls opaque 
.v-carousel .v-btn::before {
  opacity: 0.07; 
  z-index: 2;
}
*/

/* Make the carousel arrows and controls opaque on hover */
.v-carousel:hover .v-btn::before {
  opacity: 1;
  /* Adjust the opacity on hover as needed */
}

/* Style the bottom action bar */
.bottom-action-bar {
  /*background-color: white;  Background color of the action bar */
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px;
  /*Adjust padding as needed */
  text-align: center;
  /* Center the text horizontally */
  color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #ccc;
  /* Adjust the color to a lighter grey */
  font-size: 13px;
  border-radius: 0 0 10px 10px;
  /* Add rounded corners to the bottom */


}

.action-link {
  text-decoration: none;
  /* Remove default underline */
  transition: text-decoration 0.3s;
  /* Add transition for smooth hover effect */
  color: black;
}

.action-link:hover {
  text-decoration: underline;
  /* Underline on hover */
}

.feature-content {
  /* Add some margin to the bottom of the feature content to make space between content text and action bar*/
  margin-bottom: 60px;
  /* Adjust the margin as needed */
}

.icon-space {
  margin-right: 10px;
  /* Adjust the margin to control the spacing between icons */
}



.full-width-bar {
  background-color: white;
  width: 100%;
  padding: 20px 0;
  overflow: hidden;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align icons to the start of the container */
  align-items: center; /* Center icons vertically */
  gap: 10px; /* This replaces margin-right from .icon-space and adds vertical gap */
}

.icon-row {
  padding-left: 25%; /* Add left padding to the entire row */
}

/* Center icons in .icon-container on smaller screens */
@media (max-width: 600px) {
  .icon-container {
    justify-content: center; /* Center icons horizontally when they wrap */
  }
}

.icon-row {
  padding-left: 25%; /* Add left padding to the entire row */
  width: 100%; /* Ensure the row takes full width to allow centering within it */
}

@media (max-width: 600px) {
  .icon-row {
    padding-left: 0; /* Remove left padding on smaller screens */
    justify-content: center; /* Ensures icons are centered on mobile screens */
  }
}

.bar-icon {
 
  flex: 0 1 auto; /* Do not grow, do not shrink, based on content size */
  max-height: 30px;
  }



  .bar-icon-bsd {
 
 flex: 0 1 auto; /* Do not grow, do not shrink, based on content size */
 max-height: 40px;
 }

 .bar-icon-illumos {
 
 flex: 0 1 auto; /* Do not grow, do not shrink, based on content size */
 max-height: 40px;
 }

/*
@media (max-width: 768px) {
  .bar-icon {
    max-height: 20px;  If you want to keep the icons' size, consider removing this rule 
  }
}*/
/* Default font size for larger screens */
.gradient-text {
  user-select: none; 
  font-size: 16px;
  font-weight: 900;
  /* Set the font weight */
  font-family: 'SF Pro Display', sans-serif;
  /* Adjust the font size as needed */
}

/* Font size for smaller screens (e.g., mobile) */
@media (max-width: 768px) {
  .gradient-text {
    font-size: 14px;
    /* Adjust the font size as needed for smaller screens */
  }
}






/*
@keyframes slideIcons {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}*/

.v-card {
  max-width: 500px;
  /* Limit the width for a more card-like appearance */
  margin: 0 auto;
  /* Center the card in the container */
  border: 1px solid #000;
  /* Add a simple border to the card */
}

.transparent-input .v-input__slot {
  background-color: transparent !important;
}

.background-overlay::before {
  content: '';
  background-image: url('@/assets/mcu.png');
  background-size: contain;
  background-position: center center;
  opacity: 0.03;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.background-overlay {
  position: relative;
}</style>